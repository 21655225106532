<div class="map-container" *ngIf="checkLoginStatus">
  <div class="search-bar" [ngClass]="{'search': isAdmin !== true}">
    <input id="autocomplete-input" [formControl]="locationControl" type="text" [(ngModel)]="searchValue"
      class="search-input" placeholder="Search locations..." />
    <span class="clear-icon" *ngIf="!searchValue">
      <fa-icon [icon]="faSearch"></fa-icon>
    </span>
    <span class="clear-icon" (click)="clearAddress()" *ngIf="searchValue">
      <fa-icon [icon]="faClose"></fa-icon>
    </span>
  </div>
  <google-map width="100%" height="100%" [center]="center" [zoom]="zoom" [options]="mapOptions"
    (mapClick)="onMapClick($event)">
    <map-marker-clusterer imagePath="../../assets/images/m">
      <map-marker #infoWindow="mapMarker" *ngFor="let marker of markers; index as i" [position]="marker.position"
        (mapClick)="openInfoWindow(infoWindow,i)" [options]="{
      animation: google.maps.Animation.DROP,
   icon: {
    url: encodedSvg,
   }
    }">
      </map-marker>
    </map-marker-clusterer>
    <map-info-window>
      <div style="width: 301px;">
        <img [src]="markers[index]?.data?.projectImage?.objectUrl" alt="" class="img-w">
        <p>Project Name: {{markers[index]?.data?.projectName}}</p>
        <p>Price: {{markers[index]?.data?.totalPrice}}</p>
        <p>Size: {{markers[index]?.data?.minimumSize?.value}} {{markers[index]?.data?.minimumSize?.unitType}}</p>
        <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Show Details"
          (click)="openProjectView(markers[index])"></app-common-button>
      </div>
    </map-info-window>
  </google-map>
</div>
<div *ngIf="!checkLoginStatus">
  <app-login-popup [isVisible]="isLoginModalVisible" [isMapView]="true" (closeModal)="closeProjectModal()"></app-login-popup>
</div>