import { Component, HostListener, OnInit } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import { FilterService } from '../../services/filters/filter.service';
import { Router } from '@angular/router';
import { StorageKeys } from '../share/enums/storage.enum';
import { StorageService } from '../../services/storage/storage.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-invest-properties',
  templateUrl: './invest-properties.component.html',
  styleUrl: './invest-properties.component.scss'
})
export class InvestPropertiesComponent implements OnInit {
  isSingleImage: boolean = false;

  constructor(
    private propertyService: PropertyService,
    private filterService: FilterService,
    private router: Router,
    private storageService: StorageService,
  ) { }
  categories: any = [];
  visibleImages: any = [];
  currentPage: number = 0;
  imagesPerPage: number = 4;
  showNextPage: boolean = false;
  public faRight = faArrowRight

  ngOnInit() {
    this.fetchCategories();
    this.updateImagesPerPage();
  }

  fetchCategories() {
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.categories = res?.data;
        let obj: any = [];
        res?.data.forEach((x: any) => {
          obj.push({
            name: x.name,
            _id: x._id
          })
        });
        this.storageService.setDataToLocalStorage(
          StorageKeys.MODULENAME,
          obj
        );
        this.storageService.setDataToLocalStorage(
          StorageKeys.MODULEID,
          obj.id
        )
        this.updateVisibleImages()
      },
      error: (error: any) => {
      }
    })
  }

  updateVisibleImages() {
    const startIndex = this.currentPage * this.imagesPerPage;
    const endIndex = startIndex + this.imagesPerPage;
    this.visibleImages = this.categories.slice(startIndex, endIndex);
  
    // Check if there's only one image on the current page and mark it
    this.isSingleImage = this.visibleImages.length === 1;
  
    if ((this.currentPage + 1) * this.imagesPerPage < this.categories.length) {
      this.showNextPage = true;
    } else {
      this.showNextPage = false;
    }
  }
  

  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.updateVisibleImages();
    }
  }

  nextPage() {
    if ((this.currentPage + 1) * this.imagesPerPage < this.categories.length) {
      this.currentPage++;
      this.updateVisibleImages();
    }
  }

  onCategoryClicked(name: string) {
    const filterObject = this.constructFilterObject(name);
    const filteredValues = Object.keys(filterObject).reduce((acc: any, key) => {
      if (filterObject[key]) {
        acc[key] = filterObject[key];
      }
      return acc;
    }, {});

    this.filterService.updateFilterObject(filteredValues);

    this.router.navigate(['properties-list'])
  }

  private constructFilterObject(name: any): any {
    return {
      propertyType: name,
    };
  }

  updateImagesPerPage() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1200) {
      this.imagesPerPage = 4; 
    } else if (screenWidth >= 768 && screenWidth < 1200) {
      this.imagesPerPage = 3; 
    } else {
      this.imagesPerPage = 2; 
    }
    this.updateVisibleImages();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateImagesPerPage();
  }
}
