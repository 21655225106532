<div class="main-div">
    <div class="featured-properties">
        <div class="featured-properties-heading">Featured Properties</div>
        <!-- <div class="featured-properties-title">The well-furnished home and apartments are available</div> -->
    </div>
    <div class="properties-box-section">
        <div class="row">
            <div class="properties-box" *ngFor="let properties of featuredProperties" (click)="openProjectView(properties)">
                <img [src]="properties.galleryPics[0].objectUrl" alt="img" >
                <div class="row property-custom-box">
                    <div class="col property-details-blockone">
                        <p class="property-name">{{properties.projectName}}</p>
                        <p class="property-address">{{properties.address.locality}},{{properties.address.city}}</p>
                        <p class="property-designation">{{properties.projectBy}}</p>
                        <p class="property-designation">{{properties.description}}</p>
                    </div>
                    <div class="col property-type-block">
                        <div class="property-type">{{properties.subcategoryName}}</div>
                        <div class="property-price" *ngIf="properties.units?.length > 0">{{properties.formattedPrice}}*<span>onwards</span></div>
                        <div class="property-price" *ngIf="properties.units?.length===0"> ₹ {{getTotalAsset(properties?.minimumPrice,properties?.size?.value) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
