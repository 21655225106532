<div class="side-nav-bar__body" [ngClass]="{'expanded': isExpanded}" (click)="toggleSidebar()">
<div class="side-nav-bar__bodySection">
        <div class="side-nav-bar__logo">
            <a href="#">
                <img class="side-nav-bar__logoSection" src="../../assets/images/Property box Trasnparent_sidebar.png">
            </a>
        </div>
        <div class="side-bar-list">
            <div class="side-bar-listsection">
                <div class="side-bar-item" (click)="dashboard()" [ngClass]="{'active': activeItem === 'dashboard'}">
                    <img class="item-icon" src="../../assets/images/element-2 1.png">
                    <span class="item-text">Dashboard</span>
                </div>
                <div class="side-bar-item" (click)="propertyList()" [ngClass]="{'active': activeItem === 'properties'}">
                    <img class="item-icon" src="../../assets/images/residential.png">
                    <span class="item-text">Properties</span>
                </div>
                <div *ngIf="showPropertyItems" class="side-bar-sub-items">
                    <div class="side-bar-item sub-item" (click)="addPropertyPage()" [ngClass]="{'active': activeSubItem === 'addProperty'}">
                        <span class="sub-item-text">Add Property</span>
                    </div>
                    <div class="side-bar-item sub-item" (click)="properties()" [ngClass]="{'active': activeSubItem === 'profileList'}">
                        <span class="sub-item-text">Property List</span>
                    </div>
                </div>
                <div class="side-bar-item" (click)="enquiries()" [ngClass]="{'active': activeItem === 'enquiries'}">
                    <img class="item-icon" src="../../assets/images/personalcard.png">
                    <span class="item-text">Enquiries</span>
                </div>
                <div class="side-bar-item" (click)="masterData()" [ngClass]="{'active': activeItem === 'metadata'}">
                    <img class="item-icon" src="../../assets/images/icons8-properties-96.png">
                    <span class="item-text">Assets</span>
                </div>
                <div class="side-bar-item" (click)="mapView()" [ngClass]="{'active': activeItem === 'mapview'}">
                    <img class="item-icon" src="../../assets/images/map.png">
                    <span class="item-text">Map View</span>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="side-nav-bar__footer">
        <div class="side-bar-item" (click)="profile()" [ngClass]="{'active': activeItem === 'profile'}">
            <img class="item-icon" src="../../assets/images/frame.png">
            <span class="item-text">Profile</span>
        </div>
        <!-- <div class="side-bar-item" (click)="setActiveItem('settings')" [ngClass]="{'active': activeItem === 'settings'}">
            <img class="item-icon" src="../../assets/images/setting-2.png">
            <span class="item-text">Settings</span>
        </div> -->
        <div class="side-bar-item" (click)="logOut()" [ngClass]="{'active': activeItem === 'logout'}">
            <img class="item-icon" src="../../assets/images/logout.png">
            <span class="item-text">Logout</span>
        </div>
    </div>
</div>
<button class="sidebar-toggle-button" (click)="toggleSidebar()" [ngClass]="{'active': isExpanded}">
  <fa-icon [icon]="isExpanded ? faArrowLeft : faBar" class="back-icon" [ngbTooltip]="'Menu'" alt="Menu Icon"
           placement="right"></fa-icon>
</button>
