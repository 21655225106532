import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login/login.service';
import { StorageService } from '../../services/storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageKeys } from '../share/enums/storage.enum';
import { RegExpPatterns } from '../utilites/regex-pattern';
import { PropertyService } from '../../services/property/property.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrl: './login-popup.component.scss'
})
export class LoginPopupComponent {

  loginForm !: FormGroup
  showForm2 : boolean = false
  showform1 : boolean = true;
  showForm3 : boolean = false;
  verifyLoader : boolean = false;
  errorMessage : any;
  userDetails  : any;
  signupForm !: FormGroup;
  createForm ! : FormGroup;
  @Input() isVisible: boolean = false;
  @Input() isMapView: boolean = false;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService,
    private propertyService: PropertyService,
    private router      : Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.generateLoginForm();
    this.generateSignUpForm();
    this.generateCreateForm();
  }


  close() {
    this.isVisible = false;
    this.closeModal.emit();
    this.loginForm.reset();
    this.signupForm.reset();
    this.createForm.reset();
    this.showform1 = true;
    this.showForm2 = false;
    this.showForm3 = false;
    if (this.router.url.includes('map-view')) {
      this.back();
    }
  }


  generateLoginForm() {
    this.loginForm = this.fb.group({
      mobileNumber: ['',[
        Validators.required,
        Validators.pattern(RegExpPatterns.MobilePattern),
      ]],
    })
  }

  generateSignUpForm() {
    this.signupForm = this.fb.group({
      phoneNumber : ['',[Validators.required] ],
      otp: ['', [Validators.required]],
      name: ['', [Validators.required]],

    });
  }

  generateCreateForm(){
    this.createForm = this.fb.group({
      phoneNumber : ['', [Validators.required]],
      otp: ['', [Validators.required]],
    })
  }

  generateOtp() {
    this.verifyLoader = true;
    const credentials = {
      phoneNumber: this.loginForm.value.mobileNumber,
    }
    this.loginService.generateOtp(credentials)
    ?.subscribe({
      next: (res : any) => {
          this.verifyLoader = false;
          const mobileNumber = this.loginForm.value.mobileNumber;
          if (res.data.isExists == false) {
            this.signupForm.patchValue({phoneNumber: mobileNumber})
            this.showForm2 = true;
            this.showform1 = false;
            this.showForm3 = false;
          }
          if(res.data.isExists == true){
            this.createForm.controls['phoneNumber'].setValue(mobileNumber)
            this.showForm3 = true;
            this.showForm2 = false;
            this.showform1 = false;
          }
        },
        error: (error : any) => {
          this.verifyLoader = false;
          this.errorMessage = error?.error?.error?.message;
          console.log(this.errorMessage)
        },
      })
  }

  submitOtp(){
    this.verifyLoader = true;
    const credentials = {
      phoneNumber : this.loginForm.value.mobileNumber,
      otp : this.createForm.value.otp || this.signupForm.value.otp,
      name: this.signupForm.value.name 
    }
    this.loginService.submitOtp(credentials)?.subscribe({
      next: (res : any) => {
        if(res?.data){
          this.verifyLoader = false;
          this.storageService.setDataToLocalStorage(
            StorageKeys.enrollmentType,
            res.data.enrollmentType
          );
          this.storageService.setDataToLocalStorage(
            StorageKeys.PHONE_NUMBER,
            res.data.phoneNumber
          );
        }

        if(res?.data?.token){
          this.storageService.setDataToLocalStorage(
            StorageKeys.ACCESS_TOKEN,
            res.data.token
          );
          this.storageService.setDataToLocalStorage(
            StorageKeys.USER_ID,
            res.data.user
          );
          this.getuserDetails();
          this.router.navigate(['/home'])
          this.closeModal.emit(true);
        }
        this.isVisible = false;
        
      },
      error: (error : any) => {
        this.verifyLoader = false
        this.errorMessage = error?.error?.error?.message;
        this.createForm.reset();
        
        
      },
    })
  }

  getuserDetails() {
    this.propertyService.getuserDetails()?.subscribe({
      next: (res: any) => {
        this.userDetails = res?.data;
        if(this.userDetails){
          this.storageService.setDataToLocalStorage(
            StorageKeys.NAME,
            res.data.name
          )
          this.storageService.setDataToLocalStorage(
            StorageKeys.PROFILE,
            res.data?.profileUrl?.objectUrl
          )
        }
      },
      error: (error: any) => {
      }
    });
  }

  Login(){
    this.verifyLoader = true;
    if (this.isMapView) {
      this.router.navigate(['login'], { queryParams: { map: true } });
    } else {
      this.router.navigate(['login']);
    }    this.verifyLoader = false;
  }

  back() {
    this.location.back();
  }

}
