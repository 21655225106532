<div class=" m-3">
    <div class="row  property-images-block">
        <ngb-carousel #carousel [interval]="3000" [showNavigationIndicators]="false"
        [showNavigationArrows]="propertyList.length > 1">
        <ng-template *ngFor="let item of propertyList; index as imageIndex" ngbSlide>
            <img class="carousel-image" src="{{item.img}}" alt="...">
        </ng-template>
    </ngb-carousel>
        <div class="bottom">
            <app-filter (searchEvent)="searchProperties($event)"></app-filter>
        </div>
 </div>
</div>
<div id="featured"> 
    <app-featured-properties></app-featured-properties>
</div>
<div id="about">
    <app-aboutus></app-aboutus>
</div>
<div id="categories">
    <app-invest-properties></app-invest-properties>
</div>
<div id="testimonials">
    <app-customer-reviews></app-customer-reviews>
</div>
<div id="investors">
    <app-investors-reviews></app-investors-reviews>
</div>
<div id="contactus">
    <app-contact-us></app-contact-us>
</div>
<div>
    <app-footer></app-footer>
</div>