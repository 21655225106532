<div class="main">
    <div class="row justify-content">
        <div class="col">
            <img src="../../assets/images/contactus.jpeg">
        </div>
        <div class="container-fluid col data">
            <div class="move">
                <p class="mainHeading"> Let’s level up your brand, together</p>
                <span class="description">You can reach us anytime via </span><span class="address">
                    propertybox&#64;com</span>
            </div>
            <form [formGroup]="contactUsForm">
                <div class="cus-feild">
                    <app-input-filed label="Name" placeholder="Enter Name"   [control]="$any(contactUsForm).controls['name']"></app-input-filed>
                    <div class="errorText"
                        *ngIf="contactUsForm.controls['name'].invalid && (contactUsForm.controls?.['name']?.dirty || contactUsForm.controls['name'].touched)">
                        <div *ngIf="contactUsForm.controls['name'].errors?.['required']">
                            <small class="text-danger"> Name is required</small>
                        </div>
                    </div>
                    <app-input-filed label="Email" placeholder="Enter Email"  [control]="$any(contactUsForm).controls['email']"></app-input-filed>
                    <div class="errorText"
                        *ngIf="contactUsForm.controls['email'].invalid && (contactUsForm.controls?.['email']?.dirty || contactUsForm.controls['email'].touched)">
                        <div *ngIf="contactUsForm.controls['email'].errors?.['pattern']">
                            <small class="text-danger">Please enter valid  email</small>
                        </div>
                        <div *ngIf="contactUsForm.controls['email'].errors?.['required']">
                            <small class="text-danger"> Email is required</small>
                        </div>
                    </div>
                    <app-input-filed label="Mobile Number" placeholder="Enter 10 digit mobile number"
                        [control]="$any(contactUsForm).controls['phoneNumber']" [allowedType]="'number'"
                        [maxDigits]="10"></app-input-filed>
                </div>
                <div class="errorText"
                    *ngIf="contactUsForm.controls['phoneNumber'].invalid && (contactUsForm.controls?.['phoneNumber']?.dirty || contactUsForm.controls['phoneNumber'].touched)">
                    <div *ngIf="contactUsForm.controls['phoneNumber'].errors?.['pattern']">
                        <small class="text-danger">Please enter valid mobile number</small>
                    </div>
                    <div *ngIf="contactUsForm.controls['phoneNumber'].errors?.['required']">
                        <small class="text-danger">Mobile number is required</small>
                    </div>
                </div>
                <div *ngIf="errorMessage">
                    <small class="text-center text-danger">{{ errorMessage }}</small>
                </div>
                <div class="move-button">
                    <app-common-button buttonLabel="Continue" buttonClass="smaiifilledbutton" (clickEvent)="addContactusDetails()"></app-common-button>
                </div>
                <div>
                    <p class="res-data">{{message}} </p>
                </div>
            </form>
        </div>
    </div>
</div>
