import { Component, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { LoginService } from '../../services/login/login.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { StorageService } from '../../services/storage/storage.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {

  isAdmin: boolean = false;
  menuVisible: boolean = false;
  userDetails: any;
  protected userCircleIcon = faUserCircle;
  dropdownOpen:boolean = false;
  isOffCanvasOpen = false;


  constructor(
    private router: Router,
    private loginService: LoginService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.userDetails = {
      name: this.storageService.getDataFromLocalStorage(
        StorageKeys.NAME
      )
    };
    this.activatedRoute.queryParams?.subscribe(params => {
      const section = params['section'];
      if (section) {
        setTimeout(() => {
          const element = document.getElementById(section);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      }
    });
  }

  logIn() {
    this.router.navigate(['login']);
  }

  mapView() {
    this.router.navigate(['map-view']);
  }

  profile() {
    this.router.navigate(['user-profile']);
  }


  get checkLoginStatus() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  get isLogin() {
    return window.location.href.includes('login');
  }

  logOut() {
    this.loginService.logOut().subscribe(res => {
      if (res.data) {
        this.isAdmin = false;
      }
    });
    localStorage.clear();
    this.back();
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  toggleOffCanvas() {
    this.isOffCanvasOpen = !this.isOffCanvasOpen;
  }

  scrollToSection(section: string) {
    if (this.router.url === '/home') {
      this.scrollToElement(section);
    } else {
      this.router.navigate(['/home']).then(() => {
        setTimeout(() => {
          this.scrollToElement(section);
        }, 100); 
      });
    }
  }
  
  private scrollToElement(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  

  back() {
    this.location.path();
  }

  //close menuVisible when we click outside of the menu
  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const dropdownElement = this.elementRef.nativeElement.querySelector('.profile-dropdown-menu');
    if (!dropdownElement?.contains(target)) {
      this.menuVisible = false;
    }
  }

  home() {
    this.router.navigate(['home']);
  }

  properties(){
    this.router.navigate(['/properties-list']);
  }
}
