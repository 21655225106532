import { Component, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import {GoogleMap, MapMarker, MapMarkerClusterer, MapInfoWindow} from '@angular/google-maps';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Router } from '@angular/router';
import { PriceFormatterService } from '../../services/price-formatter/price-formatter.service';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-properties-mapview',
  templateUrl: './properties-mapview.component.html',
  styleUrl: './properties-mapview.component.scss',
})
export class PropertiesMapviewComponent implements OnInit {
  google: any = google
  center: google.maps.LatLngLiteral = { lat: 17.406498, lng: 78.47724389999999 };
  zoom = 12;
  markers: any = [];
  activeCard: any = null;
  cardPosition = { top: '0px', left: '0px' };
  index: any;
  isAdmin: boolean = false;
  status: any;
  searchValue: string = '';
  protected faClose = faClose;
  protected faSearch = faSearch;
  locationControl = new FormControl('', [Validators.required]);
  formattedAddress: any;
  savedAddress: any = null;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow !: MapInfoWindow;
  @ViewChild(GoogleMap) map!: GoogleMap;
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false, 
    fullscreenControl: false,
    streetViewControl: false, 
    zoomControl: true, 
  };
  isLoginModalVisible: boolean = false;
  svgIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="48px" height="48px">
  <path d="M12,2C8.13,2 5,5.13 5,9c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zM12,11.5c-1.38,0 -2.5,-1.12 -2.5,-2.5s1.12,-2.5 2.5,-2.5 2.5,1.12 2.5,2.5 -1.12,2.5 -2.5,2.5z"/>
</svg>`;
  encodedSvg = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(this.svgIcon)}`;

  


  constructor(private propertyService:PropertyService,
    public priceFormatterService: PriceFormatterService,
    private router: Router,
    private ngZone: NgZone,
    private storageService: StorageService,
   ){}

  ngOnInit() {
    this.getBirdViewData();
        this.locationControl.valueChanges.subscribe((value) => {
          if (!value) {
            this.clearAddress();
            this.onLocationSelected(null);
          }
        });
        this.getPlaceAutocomplete();
        if (!this.checkLoginStatus) {
          this.openProjectModal();
        }
  }

  openProjectView(property: any) {
    this.router.navigate(['/property-details'], { queryParams: { pid: property.data._id } });
  }

  getBirdViewData(){
    this.propertyService.getBirdView().subscribe({
      next : (res: any)=>{
        if(res?.data){
          res.data.forEach((location: any) => {
            let obj = {
              position : {
                lat : location?.address?.location[0],
                lng : location?.address?.location[1]
              },
              data : {
                ...location
              }
            }
            this.markers.push(obj);
            setTimeout(() => {
              this.fitMapBounds();
            }, 2000);
            });
        }
      }
    })
  }

  onMarkerClick(event: any, markerData: any): void {
    this.activeCard = markerData;
    this.cardPosition = {
      top: `${event.domEvent.y}px`,
      left: `${event.domEvent.x}px`
    };
  }

  onMapClick(e: any) {
    this.activeCard = null;
  }

  openInfoWindow(infoWindow:MapMarker, index: any) {
    this.index = index;
    this.infoWindow.open(infoWindow);
  }

  getLocalStorageDetails() {
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  onLocationSelected(address: any) {
    if (address && address.location) {
      this.ngZone.run(() => {
        this.center = {
          lat: address.location[0],
          lng: address.location[1],
        };
        this.zoom = 15;
      });
    }
  }

  private getPlaceAutocomplete() {
    const options = {
      componentRestrictions: { country: 'IN' },
      types: ['geocode'],
    };
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete-input') as HTMLInputElement,
      options
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.formattedAddress = place.formatted_address;
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    if (!place || !place.geometry) {
      return;
    }
    const address: any = {};
    address.city = this.getAddrComponent(place.address_components, { locality: 'long_name' });
    address.state = this.getAddrComponent(place.address_components, { administrative_area_level_1: 'long_name' });
    address.country = this.getAddrComponent(place.address_components, { country: 'long_name' });
    address.pincode = this.getAddrComponent(place.address_components, { postal_code: 'long_name' });
    address.locality = this.getAddrComponent(place.address_components, { sublocality_level_1: 'long_name' });
    address.formatAddress = this.formattedAddress;
    address.location = [
      place.geometry.location.lat(),
      place.geometry.location.lng(),
    ];
    this.savedAddress = address;
    this.onLocationSelected(address);
  }

  getAddrComponent(component: any, componentTemplate: any) {
    for (var i = 0; i < component?.length; i++) {
      let addressType = component[i].types[0];
      if (componentTemplate[addressType]) {
        return component[i][componentTemplate[addressType]];
      }
    }
  }

  clearAddress() {
    this.center = { lat: 17.406498, lng: 78.47724389999999 }; 
    this.zoom = 12; 
    this.locationControl.setValue('');
    this.savedAddress = null;
    this.searchValue = '';
  }

  get checkLoginStatus() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  openProjectModal() {
    this.isLoginModalVisible = true;
  }

  closeProjectModal() {
    this.isLoginModalVisible = false;
  }

  fitMapBounds() {
    if (this.map && this.map.googleMap) {
      const bounds = new google.maps.LatLngBounds();      
      this.markers.forEach((marker: any) => {
        const latLng = new google.maps.LatLng(marker.position.lat, marker.position.lng);
        bounds.extend(latLng);
      });
      this.map.googleMap?.fitBounds(bounds);
    }
  }
  
}


