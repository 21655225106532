<div>
    <div class="aboutus-heading">One trusted platform for all your real estate needs.</div>
    <div class="aboutus-block" id="aboutUsSection">
        <div class=" row aboutus-mainblock">
            <div class="col aboutus-content-block">
                <h4 class="aboutus-title">About Us</h4>
                <div class="aboutus-content">Welcome to Property Box, where we've ushered in a new era of real estate,
                    simplifying the process and elevating it to corporate standards. Say goodbye to the old ways - we've
                    revolutionized real estate, offering hassle-free investment opportunities with exceptional growth
                    potential, spanning from farm plots to DTPC plots. With our user-friendly website, clients can
                    effortlessly manage their entire portfolio, ensuring convenience and clarity every step of the way.
                </div>
                <!-- <div class="col d-flex gap-5 mt-5 mb-5">
                    <app-common-button buttonClass="smallButton" buttonLabel="Book Now!"></app-common-button>
                    <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Read More"></app-common-button>
                </div> -->
                <!-- <div class="row g-5 image">
                    <img src="../../assets/images/Property box logo with out back ground (1).png">
                    
                    <div class="col">
                        <div class="col info-count counter" id="officeCounter"></div>
                        <div class="info-content">Offices</div>
                    </div>
                    <div class="col">
                        <div class="col info-count counter" id="studentCounter"></div>
                        <div class="info-content">Properties Listed</div>
                    </div>
                    <div class="col">
                        <div class="col info-count counter" id="customerCounter"></div>
                        <div class="info-content">Customers</div>
                    </div>
                    
                </div> -->
            </div>
            <div class="col aboutus-image-block">
                <img src="../../assets/images/icon.jpg">
            </div>
        </div>
    </div>
</div>