<div class="footer">
    <div class="footer-list">
        <div class="row footer-list-block">
            <div class="col footer-logo">
                <a class="navbar-brand" href="#"><img src="../../assets/images/Property box Trasnparent.png"></a>
            </div>
            <div class="col footer-list-about">

                <ul>
                    <div class="list-item-heading">About</div>
                    <li>Company</li>
                    <li> <a class="nav-link" href="javascript:void(0)" (click)="scrollToSection('contactus')">Contact
                            Us</a></li>
                    <li>Terms and Service</li>
                    <li class="privacy-policy" (click)="privacyPolicy()"> Privacy Policy</li>
                </ul>
            </div>
            <div class="col footer-list-product">
                <ul>
                    <div class="list-item-heading">Product</div>
                    <li> <a class="nav-link" href="javascript:void(0)"
                            (click)="scrollToSection('categories')">Categories</a></li>
                    <li>Pricing</li>
                    <li>Testimonials</li>
                </ul>
            </div>
            <div class="col footer-list-discover">
                <ul>
                    <div class="list-item-heading">Discover</div>
                    <li>Teams</li>
                    <li>Partners</li>
                    <li>Career</li>

                </ul>
            </div>
            <div class="col footer-list-followus">
                <ul>
                    <div class="list-item-heading">Follow us</div>
                    <ul class="social-media-icons">
                        <li><img src="assets/images/Facebook Circled.png"></li>
                        <li><img src="assets/images/Twitter Circled.png"></li>
                        <li><img src="assets/images/LinkedIn Circled.png"></li>
                    </ul>
                </ul>
            </div>
        </div>
    </div>
    <div class="copy-rights">
        <div class="copy-rights-content">© 2024 - All Rights Reserved &#64;propertybox </div>
    </div>
</div>