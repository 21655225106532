<div class="main_contenet">
    <div class="main_container">
        <div class="main_header">
            Investment Properties
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <div class="sde-btn">
                <button class="btn me-md-2" [ngClass]="{'active': currentPage > 0}" (click)="prevPage()">
                    <
                </button>
                <button class="btn" [ngClass]="{'active': showNextPage}" (click)="nextPage()">
                    >
                </button>
            </div>
        </div>
        <div class="image_content" [ngClass]="{'one-card': isSingleImage}">
            <div class="image-card" *ngFor="let image of visibleImages">
                <img [src]="image.categoryDocs[0].objectUrl" class="card-img-top" alt="..."  (click)="onCategoryClicked(image.name)">
                <div class="card-up">
                    <small class="image-text" >{{ image.name }} </small>
                </div>
            </div>
        </div>
    </div>
</div>
