<div>
    <div class="mainblock">
        <div class="col add-property-mainblock">
            <div class="properties-heading-block">
                <div class="properties-heading">{{ id ? 'Update Property' : 'Add Property' }}</div>
                <div class="heading-border"></div>
            </div>
            <div class="property-details">
                <div class="property-details-title">Property Details</div>
                <div>
                    <form [formGroup]="addpropertyFormGroup">
                        <div class="d-flex  addproperty-blockone">
                            <div>
                                <div class="inputField__label">
                                    Asset Type <span style="color:red;">*</span>
                                </div>
                                <select class="addProperty__dropdownOptions" formControlName="asset">
                                    <option value="" selected disabled class="addProperty-dropdown-options"
                                        style="color: gray;">
                                        Select Asset Type
                                    </option>
                                    <option *ngFor="let assetsList of assetsList" [ngValue]="assetsList?._id">
                                        {{assetsList.name}}
                                    </option>
                                </select>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['asset'].invalid && (addpropertyFormGroup.controls?.['asset']?.dirty || addpropertyFormGroup.controls['asset'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['asset'].errors?.['required']">
                                        <small class="text-danger">Select Assets Type</small>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="inputField__label">
                                    Category <span style="color:red;">*</span>
                                </div>
                                <select class="addProperty__dropdownOptions" formControlName="categoryList">
                                    <option value="" selected disabled class="addProperty-dropdown-options"
                                        style="color: gray;">
                                        Select Category
                                    </option>
                                    <option *ngFor="let category of categoryList" [ngValue]="category?._id">
                                        {{category.name}}
                                    </option>
                                </select>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['categoryList'].invalid && (addpropertyFormGroup.controls?.['categoryList']?.dirty || addpropertyFormGroup.controls['categoryList'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['categoryList'].errors?.['required']">
                                        <small class="text-danger">Select Category</small>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="inputField__label">
                                    Sub Category <span style="color:red;">*</span>
                                </div>
                                <select class="addProperty__dropdownOptions" formControlName="subcategory">
                                    <option value="" selected disabled class="addProperty-dropdown-options"
                                        style="color: gray;">
                                        Select Sub Category
                                    </option>
                                    <option *ngFor="let subCategory of subCategoryList" [ngValue]="subCategory?._id">
                                        {{subCategory.name}}
                                    </option>
                                </select>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['subcategory'].invalid && (addpropertyFormGroup.controls?.['subcategory']?.dirty || addpropertyFormGroup.controls['subcategory'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['subcategory'].errors?.['required']">
                                        <small class="text-danger">Select Category</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property">
                            <div class="d-flex addproperty-blockone">
                                <div>
                                    <app-input-filed label="Project Name" placeholder="Enter Project Name"
                                        [starIcon]="'*'"
                                        [control]="$any(addpropertyFormGroup).controls['projectName']"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['projectName'].invalid && (addpropertyFormGroup.controls?.['projectName']?.dirty || addpropertyFormGroup.controls['projectName'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['projectName'].errors?.['pattern']">
                                            <small class="text-danger">First letter must be alphanumeric</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['projectName'].errors?.['required']">
                                            <small class="text-danger">Project Name is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex   addproperty-blockone">
                                    <app-input-filed label="Project By" placeholder="Project By"
                                        [control]="$any(addpropertyFormGroup).controls['projectBy']"></app-input-filed>
                                    <div>
                                        <div class="inputField__label">
                                            Featured Property</div>
                                        <select class="featured__dropdownOptions" formControlName="featured"
                                            (change)="onFeaturedPropertyChange($event)">
                                            <option [value]="" selected disabled class="addProperty-dropdown-options"
                                                style="color: gray;">Is Featured Property?</option>
                                            <option [value]="false">No</option>
                                            <option [value]="true">Yes</option>
                                        </select>
                                    </div>
                                    <app-input-filed label="Featured property" placeholder=""
                                        style="visibility: hidden;"
                                        [control]="$any(addpropertyFormGroup).controls['projectName']"></app-input-filed>
                                </div>
                            </div>
                            <div class="d-flex addproperty-blockone"
                                *ngIf="!selectedCategory?.containUnits && !selectedCategory?.containPriceRange">
                                <div class="property-size">
                                    <app-input-filed label="Property Size " placeholder="Enter Size" [starIcon]="'*'"
                                        [allowedType]="'number'"
                                        [control]="$any(addpropertyFormGroup).controls['propertySize']"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['propertySize'].invalid && (addpropertyFormGroup.controls?.['propertySize']?.dirty || addpropertyFormGroup.controls['propertySize'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['propertySize'].errors?.['pattern']">
                                            <small class="text-danger">In valid property size</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['propertySize'].errors?.['required']">
                                            <small class="text-danger">Property Size is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="addproperty__dropdown">
                                    <select class="addproperty__dropdownOptions" formControlName="unit">
                                        <option value="" selected disabled>Units</option>
                                        <option [ngValue]="'sq.ft'">sq.ft</option>
                                        <option [ngValue]="'sq.yard'">sq.yard</option>
                                    </select>
                                </div>
                                <div class="property-price">
                                    <app-input-filed label="Price {{selectedUnit}}" placeholder="Enter Price"
                                        [allowedType]="'number'" [starIcon]="'*'"
                                        [control]="$any(addpropertyFormGroup).controls['price']"
                                        [maxDigits]="12"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['price'].invalid && (addpropertyFormGroup.controls?.['price']?.dirty || addpropertyFormGroup.controls['price'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['price'].errors?.['pattern']">
                                            <small class="text-danger">Price must be a valid number</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['price'].errors?.['required']">
                                            <small class="text-danger error_price">Price is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex description-box">
                                <label>Description<span style="color:red;">*</span></label>
                                <textarea placeholder="Enter Description" appNoLeadingSpaces
                                    formControlName="description"></textarea>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['description'].invalid && (addpropertyFormGroup.controls?.['description']?.dirty || addpropertyFormGroup.controls['description'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['description'].errors?.['pattern']">
                                        <small class="text-danger">First letter must be alphabet</small>
                                    </div>
                                    <div *ngIf="addpropertyFormGroup.controls['description'].errors?.['required']">
                                        <small class="text-danger">Description is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div *ngIf="selectedCategory?.containUnits">
                                    <div class="unit-details">
                                        <label class="form-label unit_type"><strong>Unit Types:</strong></label>
                                        <div>
                                            <div class="form-check form-check-inline"
                                                *ngFor="let type of selectedCategory?.unitTypes; let i = index">
                                                <input class="form-check-input" type="checkbox" [id]="'unitType' + type"
                                                    [checked]="addpropertyFormGroup.value?.unitName.includes(type)"
                                                    (change)="onUnitTypeChange($event, i,type)">
                                                <label class="form-check-label addProperty_unit"
                                                    [for]="'unitType' + type">{{ type }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label class="form-label unit_type">Unit Sizes:</label>
                                    <div class="mb-3" formArrayName="unitSize">
                                        <div *ngFor="let unit of unitSizeArray.controls; let i = index"
                                            [formGroupName]="i" class="row">
                                            <div class="col-md-3">
                                                <label class="form-label">{{ getTypes(i) }}: <span
                                                        style="color:red;">*</span></label>
                                                <div class="size-fields">
                                                    <input type="number" formControlName="minSize"
                                                        class="form-control input_size no-spinner default-form-group-border"
                                                        placeholder="Min Size" required
                                                        min="1" oninput="validity.valid||(value='');">
                                                    <input type="number" formControlName="maxSize"
                                                        class="form-control input_size2 no-spinner default-form-group-border"
                                                        placeholder="Max Size" required
                                                        min="1" oninput="validity.valid||(value='');">
                                                    <select class="unitType__dropdownOptions" formControlName="unitType"
                                                        required>
                                                        <option value="" selected disabled>Units</option>
                                                        <option [ngValue]="'sq.ft'">sq.ft</option>
                                                        <option [ngValue]="'sq.yard'">sq.yard</option>
                                                    </select>
                                                </div>
                                                <div *ngIf="unit.errors?.['maxLessThanMin']" class="text-danger">
                                                    Max size must be greater than Min size.
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <!-- Facing Section -->
                                    <div class="col-md-6" *ngIf="selectedCategory?.haveFacing">
                                        <label class="form-label unit_type">Facing:</label>
                                        <div class="mb-3"
                                            *ngFor="let facing of unitFacingArray.controls; let i = index">
                                            <label class="form-label"> {{ getTypes(i)}}: <span
                                                    style="color:red;">*</span></label>
                                            <div style="display: flex; gap: 5px;" class="unit_facing">
                                                <app-common-button *ngFor="let direction of directions"
                                                    [buttonClass]="facing.value.includes(direction) ? 'selectedButton' : 'addPropertyButton'"
                                                    [buttonLabel]="direction" (click)="toggleFacing(i, direction)">
                                                </app-common-button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="price-range" *ngIf="selectedCategory?.containPriceRange">
                                    <label class="form-label unit_type">Price Range:</label>
                                    <div formArrayName="unitPrice">
                                        <div class="row mb-2"
                                            *ngFor="let unit of unitPriceArray.controls; let i = index"
                                            [formGroupName]="i">
                                            <div class="col-md-1">
                                                <label class="form-label"> {{getTypes(i)}}: <span
                                                        style="color:red;">*</span></label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="number" formControlName="minPrice"
                                                    class="form-control no-spinner default-form-group-border" placeholder="Min Price" required
                                                    min="1" oninput="validity.valid||(value='');">
                                            </div>
                                            <div class="col-md-2">
                                                <input type="number" formControlName="maxPrice"
                                                    class="form-control no-spinner default-form-group-border" placeholder="Max Price" required
                                                    min="1" oninput="validity.valid||(value='');">
                                            </div>
                                            <div *ngIf="unit.errors?.['maxLessThanMin']" class="text-danger">
                                                Max price must be greater than min price.
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedCategory?.containTowers">
                                <div class="row mb-3">
                                    <div class="col-md-4">
                                        <label><strong>Number of Towers:<span
                                                    style="color:red;">*</span></strong></label>
                                        <input type="number" formControlName="numberOfTowers"
                                            class="form-control default-form-group-border no-spinner" min="0"
                                            oninput="validity.valid||(value='');" (input)="onTowersChange()" required>
                                    </div>
                                </div>

                                <div class="row mb-3" formArrayName="towers" *ngIf="towers.controls.length > 0">
                                    <div class="col-md-2 tower-grid">
                                        <div *ngFor="let tower of towers.controls; let i = index" [formGroupName]="i">
                                            <label>Floors in Tower {{ i + 1 }}</label>
                                            <input type="number" formControlName="value" class="form-control no-spinner default-form-group-border"
                                                required  min="1" oninput="validity.valid||(value='');">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <label class="form-label"><strong>Vicinity/Locality:</strong></label>
                                <div formArrayName="vicinity">
                                    <div *ngFor="let item of items.controls; let i = index" [formGroupName]="i"
                                        class="row g-3">
                                        <div class="col-md-3">
                                            <app-input-filed [label]="i === 0 ? 'Facilities' : ''"
                                                [value]="$any(items).controls[i].controls['facility'].value"
                                                [control]="$any(items).controls[i].controls['facility']">
                                            </app-input-filed>
                                        </div>
                                        <div class="col-md-3">
                                            <app-input-filed [label]="i === 0 ? 'Distance(kms)' : ''"
                                                [allowedType]="'number'"
                                                [value]="$any(items).controls[i].controls['distance'].value"
                                                [control]="$any(items).controls[i].controls['distance']">
                                            </app-input-filed>
                                        </div>
                                        <div class="col-md-2 delete_vicinity"
                                            *ngIf="items.length > 1 && i < items.length - 1" [ngClass]="{
                                                'delete_vicinity': true,
                                                'last-item-delete': items.length === 1,
                                                'first-delete-icon': i === 0 }">
                                            <button type="button" class="btn btn-danger" (click)="removeItem(i)">
                                                <fa-icon [icon]="faTrash" class="fa-1x"></fa-icon>
                                            </button>
                                        </div>
                                        <div class="col-md-2 add_vicinity" *ngIf="i === items.length - 1"
                                            [ngClass]="{'add_vicinity': true, 'last-item': items.length > 1}">
                                            <app-common-button buttonClass="addPropertyUnitButton"
                                                buttonLabel="Add Item" [disabled]="!fieldsLengthValid(i)"
                                                (click)="addItem()">
                                            </app-common-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="properet-address">Additional Features</div>
                                <div class="d-flex   addproperty-blockone">
                                    <div class="add-property-location">
                                        <app-input-filed label="Max Rooms" placeholder="Max Rooms"
                                            [control]="$any(addpropertyFormGroup).controls['maxRooms']"
                                            [allowedType]="'number'" [maxDigits]="4"></app-input-filed>
                                    </div>
                                    <div class="addtional_beds">
                                    <app-input-filed label="Beds" placeholder="Beds"
                                        [control]="$any(addpropertyFormGroup).controls['beds']" [allowedType]="'number'"
                                        [maxDigits]="4"></app-input-filed>
                                    </div>
                                    <app-input-filed label="Baths" placeholder="Baths"
                                        [control]="$any(addpropertyFormGroup).controls['baths']"
                                        [allowedType]="'number'" [maxDigits]="4"></app-input-filed>
                                </div>
                                <div class="d-flex additional-features-amenity">
                                    <div class="features-wrapper" [formGroup]="addpropertyFormGroup">
                                        <div formArrayName="amenities">
                                            <div class="feature-row">
                                                <div *ngFor="let feature of amenitiesList; let i = index"
                                                    class="form-check">
                                                    <input type="checkbox" class="form-check-input"
                                                        [formControlName]="i">
                                                    <img *ngIf="feature.icon" [src]="feature.icon" alt="Uploaded Image"
                                                        class="amenity-image">
                                                    <label class="form-check-label">{{ feature.name }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="rera_section">
                            <div class="form-group">
                                <label><strong>Is the Project RERA Approved? <span
                                    style="color:red;">*</span></strong></label>
                                <div class="rera-approved-toggle">
                                    <input type="radio" id="reraYes" formControlName="isTheProjectReraApproved"
                                        [value]="true">
                                    <label for="reraYes" class="btn-yes">YES</label>

                                    <input type="radio" id="reraNo" formControlName="isTheProjectReraApproved"
                                        [value]="false">
                                    <label for="reraNo" class="btn-no">NO</label>
                                </div>
                            </div>
                            <div class="form-group col-md-4"
                                *ngIf="addpropertyFormGroup.get('isTheProjectReraApproved')?.value">
                                <label for="reraNumber" class="reraNumber">RERA Number (If Yes)</label>
                                <input type="text" formControlName="reraNumber"
                                    class="form-control default-form-group-border" id="reraNumber" />
                            </div>
                        </div>
                        <div class="row mb-4">
                            <!-- <label class="form-label upload_heading"><strong>Uploads</strong>(only jpg, png, and jpeg
                                formats are allowed) :</label> -->
                            <div class="col-md-6 file_uplods">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="form-label">Brochure:</label>
                                    </div>
                                    <div class="col-md-6 upload_file">
                                        <ng-container *ngIf="!loader?.['brochureDocuments']; else loadingTemplate">
                                            <label for="brochureDocuments" class="custom-upload-button">Upload</label>
                                            <input type="file" id="brochureDocuments" class="file-input" accept="application/pdf"
                                                (change)="uploadDoc($event, 'brochureDocuments')">
                                        </ng-container>
                                        <ng-template #loadingTemplate>
                                            <app-common-icon class="custom-upload-button" [loadingIcon]="true"></app-common-icon>
                                        </ng-template>
                                    </div>
                                    <p class="upload-text">(Only pdf format is allowed)</p>
                                    <div *ngIf="fileUploadErrors?.['brochureDocuments']" class="text-danger error_msg">
                                        {{ fileUploadErrors['brochureDocuments'] }}
                                    </div>
                                </div>
                            
                                <div class="image-container" *ngIf="uploadfileList['brochureDocuments']?.length">
                                    <div *ngFor="let file of uploadfileList['brochureDocuments']; let index = index" class="image-item-block">
                                        <img src="../../assets/images/Files.svg" alt="Uploaded Image" class="uploaded-pdf">
                                        <div (click)="removeSelectedFile(index,'brochureDocuments')">
                                            <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip" ngbTooltip="Delete"
                                                [placement]="'top'"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="form-label">Floor Plan:</label>
                                    </div>
                                    <div class="col-md-6">
                                        <ng-container *ngIf="!loader?.['floorPlanningDocuments']; else loadingTemplate">
                                            <label for="floorPlanningDocuments" class="custom-upload-button">Upload</label>
                                            <input type="file" id="floorPlanningDocuments" class="file-input" accept="image/*,application/pdf"
                                                (change)="uploadDoc($event,'floorPlanningDocuments')">
                                        </ng-container>
                                        <ng-template #loadingTemplate>
                                            <app-common-icon class="custom-upload-button" [loadingIcon]="true"></app-common-icon>
                                        </ng-template>
                                    </div>
                                    <p class="upload-text">(Only pdf, jpg, png, and jpeg formats are allowed)</p>
                                    <div *ngIf="fileUploadErrors?.['floorPlanningDocuments']" class="text-danger error_msg">
                                        {{ fileUploadErrors['floorPlanningDocuments'] }}
                                    </div>
                                </div>
                                <div class="image-container" *ngIf="uploadfileList['floorPlanningDocuments']?.length">
                                    <div *ngFor="let file of uploadfileList['floorPlanningDocuments']; let index = index" class="image-item-block">
                                        <img *ngIf="file.mimeType != 'application/pdf'" [src]="file.objectUrl" alt="Uploaded Image"
                                            class="uploaded-image">
                                        <img *ngIf="file.mimeType === 'application/pdf'" src="../../assets/images/Files.svg" alt="Uploaded Image"
                                            class="uploaded-pdf">
                                        <div (click)="removeSelectedFile(index,'floorPlanningDocuments')">
                                            <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip" ngbTooltip="Delete"
                                                [placement]="'top'"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="form-label">Building Plan:</label>
                                    </div>
                                    <div class="col-md-6">
                                        <ng-container *ngIf="!loader?.['buildingPlanDocuments']; else loadingTemplate">
                                            <label for="buildingPlanDocuments" class="custom-upload-button">Upload</label>
                                            <input type="file" id="buildingPlanDocuments" class="file-input" accept="image/*,application/pdf"
                                                (change)="uploadDoc($event,'buildingPlanDocuments')">
                                        </ng-container>
                                        <ng-template #loadingTemplate>
                                            <app-common-icon class="custom-upload-button" [loadingIcon]="true"></app-common-icon>
                                        </ng-template>
                                    </div>
                                    <p class="upload-text">(Only pdf, jpg, png, and jpeg formats are allowed)</p>
                                    <div *ngIf="fileUploadErrors?.['buildingPlanDocuments']" class="text-danger error_msg">
                                        {{ fileUploadErrors['buildingPlanDocuments'] }}
                                    </div>
                                </div>
                                <div class="image-container" *ngIf="uploadfileList['buildingPlanDocuments']?.length">
                                    <div *ngFor="let file of uploadfileList['buildingPlanDocuments']; let index = index" class="image-item-block">
                                        <img *ngIf="file.mimeType != 'application/pdf'" [src]="file.objectUrl" alt="Uploaded Image"
                                            class="uploaded-image">
                                        <img *ngIf="file.mimeType === 'application/pdf'" src="../../assets/images/Files.svg" alt="Uploaded Image"
                                            class="uploaded-pdf">
                                        <div (click)="removeSelectedFile(index,'buildingPlanDocuments')">
                                            <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip" ngbTooltip="Delete"
                                                [placement]="'top'"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="form-label">Gallery Pics: <span style="color:red;">*</span></label>
                                    </div>
                                    <div class="col-md-6">
                                        <ng-container *ngIf="!loader?.['galleryDocuments']; else loadingTemplate">
                                            <label for="galleryDocuments" class="custom-upload-button">Upload</label>
                                            <input type="file" id="galleryDocuments" class="file-input" accept="image/*"
                                                (change)="uploadDoc($event,'galleryDocuments')">
                                        </ng-container>
                                        <ng-template #loadingTemplate>
                                            <app-common-icon class="custom-upload-button" [loadingIcon]="true"></app-common-icon>
                                        </ng-template>
                                    </div>
                                    <p class="upload-text">(Only jpg, png, and jpeg formats are allowed)</p>
                                    <div *ngIf="fileUploadErrors?.['galleryDocuments']" class="text-danger error_msg">
                                        {{ fileUploadErrors['galleryDocuments'] }}
                                    </div>
                                </div>
                                <div class="errorText" *ngIf="addpropertyFormGroup.controls['galleryDocuments'].invalid &&
                                                                addpropertyFormGroup.controls['galleryDocuments'].touched">
                                    <small class="text-danger">At least one gallery picture is required</small>
                                </div>
                                <div class="image-container" *ngIf="uploadfileList['galleryDocuments']?.length">
                                    <div *ngFor="let file of uploadfileList['galleryDocuments']; let index = index" class="image-item-block">
                                        <img [src]="file.objectUrl" alt="Uploaded Image" class="uploaded-image">
                                        <div (click)="removeSelectedFile(index,'galleryDocuments')">
                                            <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip" ngbTooltip="Delete"
                                                [placement]="'top'"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                                                <div class="col-md-6">
                                                                    <label class="form-label">Header Section Photos:</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="headerDocuments" class="custom-upload-button">Upload</label>
                                                                    <input type="file" id="headerDocuments" class="file-input" accept="image/*"
                                                                        (change)="uploadDoc($event,'headerDocuments')">
                                                                </div>
                                                            </div> -->
                                <!-- <div class="image-container" *ngIf="uploadfileList['headerDocuments']?.length">
                                                                <div *ngFor="let file of uploadfileList['headerDocuments']; let index = index"
                                                                    class="image-item-block">
                                                                    <img [src]="file.objectUrl" alt="Uploaded Image" class="uploaded-image">
                                                                    <div (click)="removeSelectedFile(index,'headerDocuments')">
                                                                        <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip"
                                                                            ngbTooltip="Delete" [placement]="'top'"></fa-icon>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                <!-- <div class="row">
                                                                <div class="col-md-6">
                                                                    <label class="form-label">Logo:</label>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label for="logoDocuments" class="custom-upload-button">Upload</label>
                                                                    <input type="file" id="logoDocuments" class="file-input" accept="image/*"
                                                                        (change)="uploadDoc($event,'logoDocuments')">
                                                                </div>
                                                            </div>
                                                            <div class="image-container" *ngIf="uploadfileList['logoDocuments']?.length">
                                                                <div *ngFor="let file of uploadfileList['logoDocuments']; let index = index"
                                                                    class="image-item-block">
                                                                    <img [src]="file.objectUrl" alt="Uploaded Image" class="uploaded-image">
                                                                    <div (click)="removeSelectedFile(index,'logoDocuments')">
                                                                        <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip"
                                                                            ngbTooltip="Delete" [placement]="'top'"></fa-icon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="fileUploadError" class="text-danger error_msg">
                                                                {{ fileUploadError }}
                                                            </div> -->
                                <div class="row video_link">
                                    <div class="col-md-6">
                                        <label class="form-label">Video Links:</label>
                                    </div>
                                    <div class="col-md-6 d-flex align-items-start">
                                        <input type="text" class="form-control default-form-group-border me-2" formControlName="videoLink"
                                            id="videoDocuments" placeholder="Enter YouTube link">
                                        <button class="btn btn-primary video_button" (click)="addVideoLink()">Add</button>
                                    </div>
                                </div>
                                <div class="video-container" *ngIf="uploadfileList['videoDocuments']?.length">
                                    <div *ngFor="let video of uploadfileList['videoDocuments']; let index = index" class="image-item-block">
                                        <iframe width="120" height="85" [src]="getSafeUrl(video)" anonymous>
                                        </iframe>
                                        <div (click)="removeSelectedFile(index, 'videoDocuments')">
                                            <fa-icon class="asset-delete" [icon]="faTrash" data-bs-toggle="tooltip" ngbTooltip="Delete"
                                                [placement]="'top'"></fa-icon>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="addpropertyFormGroup.controls['videoLink'].errors?.['pattern']">
                                    <small class="text-danger">Enter a valid YouTube link</small>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="properet-address">Property Address</div>
                            <div class="d-flex  addproperty-blockone">
                                <div class="add-property-location">
                                    <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode"
                                        [showStar]="true" label="Enter Location"
                                        [labelClass]="'addProperty__dropdownLabel'" [className]="'addProperty-irr'">
                                    </AutocompleteComponent>
                                </div>
                                <div class="address">
                                    <app-input-filed label="Line1" placeholder="Line1"
                                        [control]="$any(addpropertyFormGroup).controls['line1']"
                                        [starIcon]="'*'"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['line1'].invalid && (addpropertyFormGroup.controls?.['line1']?.dirty || addpropertyFormGroup.controls['line1'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['line1'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid line1</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['line1'].errors?.['required']">
                                            <small class="text-danger">Line1 is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="address">
                                    <app-input-filed label="Line2" placeholder="Line2"
                                        [control]="$any(addpropertyFormGroup).controls['line2']"
                                        [starIcon]="'*'"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['line2'].invalid && (addpropertyFormGroup.controls?.['line2']?.dirty || addpropertyFormGroup.controls['line2'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['line2'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid line2</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['line2'].errors?.['required']">
                                            <small class="text-danger">Line2 is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex  addproperty-blockone">
                                <div class="add-property-location address">
                                    <app-input-filed label="Country" placeholder="Country"
                                        [control]="$any(addpropertyFormGroup).controls['country']"></app-input-filed>
                                </div>
                                <div class="address">
                                    <app-input-filed label="State" placeholder="State"
                                        [control]="$any(addpropertyFormGroup).controls['state']"></app-input-filed>
                                </div>
                                <div class="address">
                                    <app-input-filed label="City" placeholder="City"
                                        [control]="$any(addpropertyFormGroup).controls['city']"></app-input-filed>
                                </div>
                            </div>
                            <div class="d-flex  addproperty-blockone">
                                <div class="add-property-location address">
                                    <app-input-filed label="Locality" [starIcon]="'*'" placeholder="Locality"
                                        [control]="$any(addpropertyFormGroup).controls['locality']"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['locality'].invalid && (addpropertyFormGroup.controls?.['locality']?.dirty || addpropertyFormGroup.controls['locality'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['line1'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid locality</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['locality'].errors?.['required']">
                                            <small class="text-danger">locality is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="address">
                                    <app-input-filed label="Pincode" placeholder="Pincode" [starIcon]="'*'"
                                        [allowedType]="'number'" [maxDigits]="6"
                                        [control]="$any(addpropertyFormGroup).controls['pincode']"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['pincode'].invalid && (addpropertyFormGroup.controls?.['pincode']?.dirty || addpropertyFormGroup.controls['pincode'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['pincode'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid pincode</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['pincode'].errors?.['required']">
                                            <small class="text-danger">Pincode is required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="address">
                                    <app-input-filed label="Latitude" placeholder="Latitude"  [starIcon]="'*'" 
                                    [allowedType]="'number'"
                                        [control]="$any(addpropertyFormGroup).controls['latitude']" [value]="$any(addpropertyFormGroup).controls['latitude'].value"></app-input-filed>
                                    <div class="errorText"
                                        *ngIf="addpropertyFormGroup.controls['latitude'].invalid && (addpropertyFormGroup.controls?.['latitude']?.dirty || addpropertyFormGroup.controls['pincode'].touched)">
                                        <div *ngIf="addpropertyFormGroup.controls['latitude'].errors?.['pattern']">
                                            <small class="text-danger">Enter valid Latitude</small>
                                        </div>
                                        <div *ngIf="addpropertyFormGroup.controls['latitude'].errors?.['required']">
                                            <small class="text-danger">Latitude is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="address">
                                <app-input-filed label="Longitude" placeholder="Longitude"  [starIcon]="'*'"
                                     [allowedType]="'number'"
                                    [control]="$any(addpropertyFormGroup).controls['longitude']" [value]="$any(addpropertyFormGroup).controls['longitude'].value"></app-input-filed>
                                <div class="errorText"
                                    *ngIf="addpropertyFormGroup.controls['longitude'].invalid && (addpropertyFormGroup.controls?.['longitude']?.dirty || addpropertyFormGroup.controls['pincode'].touched)">
                                    <div *ngIf="addpropertyFormGroup.controls['longitude'].errors?.['pattern']">
                                        <small class="text-danger">Enter valid Longititude</small>
                                    </div>
                                    <div *ngIf="addpropertyFormGroup.controls['longitude'].errors?.['required']">
                                        <small class="text-danger">Longitude is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="property-in-maps">
                            <img *ngIf="!addpropertyFormGroup.value?.location?.length"
                                src="assets/images/property-map.png">
                            <div class="map-div">
                                <google-map class="property-map" height="180px" width="73.61111111111111vw"
                                    [center]="center" [zoom]="zoom"
                                    *ngIf="addpropertyFormGroup.value?.location?.length">
                                    <map-marker [position]="positionData" [options]="markerOptions"></map-marker>
                                </google-map>
                            </div>
                        </div>
                        <div class=" d-flex property-buttons-block btn-generate">
                            <app-common-button buttonClass="smallfilledbuttoncancel" buttonLabel="Cancel"
                                (clickEvent)="onClose()"></app-common-button>
                            <app-common-button buttonClass="smaiifilledbuttonsave"
                                buttonLabel="{{ id ? 'Update' : 'Add' }}" [disabled]="addpropertyFormGroup.invalid"
                                [loadingIcon]="verifyLoader" (clickEvent)="generateProperty()"></app-common-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>