import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PropertyService } from '../../services/property/property.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { RegExpPatterns } from '../utilites/regex-pattern';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-add-properties',
  templateUrl: './add-properties.component.html',
  styleUrl: './add-properties.component.scss'
})
export class AddPropertiesComponent {
  center: google.maps.LatLngLiteral = {
    lat: 16.5061743,
    lng: 80.6480153
  };
  positionData: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0
  };
  display: any;
  zoom = 18;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false
  };
  markerPositions: google.maps.LatLngLiteral[] = [];
  addpropertyFormGroup !: FormGroup;
  assetsList: any;
  categoryList: any;
  subCategoryList: any;
  categoryId: any;
  additionalFeaturesamenityList: any;
  listOfFiles: { [key: string]: any[] } = {

  };
  uploadfileList: { [key: string]: any[] } = { videoDocuments: [] };
  fileList: File[] = [];
  fileUploadErrors: { [key: string]: string } = {}; 
  faTrash = faTrash;
  verifyLoader: boolean = false;
  loader: { [key: string]: boolean } = {};
  amenitiesList: any;
  selectedUnit: string = '';
  projectDetails: any;
  id: any;
  propertyEditData: any;
  directions: string[] = ['North', 'East', 'West', 'South'];
  selectedFacing: string[][] = [];
  units: number[] = [1, 2, 3];
  vicinityForm !: FormGroup;
  towersForm !: FormGroup;
  towersArray: number[] = [];
  emptyUnit: any;
  unitTypes: any;
  selectedUnitTypes: any[] = [];
  unitForm !: FormGroup;
  selectedCategory: any;
  formattedAddress : any ;


  constructor(
    private _fb: FormBuilder,
    private propertyService: PropertyService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { this.selectedFacing = Array(this.units.length).fill(''); }

  ngOnInit(): void {
    this.createContractForm();
    this.getassetsList();
    this.getAmenitiesList();
    this.getCategoryList();
    this.getUnitTypes();

    this.addpropertyFormGroup.get('categoryList')?.valueChanges.subscribe((selectedCategoryId) => {
      if (selectedCategoryId) {
        this.getsubactegoryList(selectedCategoryId);
        this.categoryId = selectedCategoryId;
        this.selectedCategory = this.categoryList?.find((category: any) => category._id === selectedCategoryId)     
        this.setConditionalValidators();
        if(this.selectedCategory.containUnits){
          this.addpropertyFormGroup.controls['unitName'].setValidators(Validators.required)
          this.addpropertyFormGroup.controls['unitName'].updateValueAndValidity()       
        }
          this.addpropertyFormGroup.controls['subcategory'].reset()    
      }
    });
    
    this.addpropertyFormGroup.get('location')?.valueChanges.subscribe((location) => {
      if (location && location.length === 2) {
        this.updateLocation(location);
      } else {
        this.clearLocation();
      }
    });
    this.addpropertyFormGroup.get('unit')?.valueChanges.subscribe((unit: string) => {
      this.selectedUnit = "per " + unit;
    });
    this.activatedRoute.queryParams?.subscribe((res: any) => {
      if (res?.pid != undefined && res?.pid != null) {
        this.showPropertyDetails(res?.pid);
        this.id = res?.pid;
      }
    })
    this.vicinityForm = this._fb.group({
      items: this._fb.array([this.createItem()]),
    });

    this.towersForm = this._fb.group({
      towers: this._fb.array([this.towerItem()])
    });

  }

  createContractForm() {
    this.addpropertyFormGroup = this._fb.group({
      asset: new FormControl('', [Validators.required]),
      categoryList: new FormControl('', [Validators.required]),
      subcategory: new FormControl('', [Validators.required]),
      projectName: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.name)]),
      propertySize: new FormControl('', []),
      price: new FormControl('', []),  
      description: new FormControl('', [Validators.required]),
      projectBy: new FormControl(''),
      featured: new FormControl(false),
      line1: new FormControl('', [Validators.required, Validators.pattern(RegExpPatterns.address)]),
      line2: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      location: new FormControl([], [Validators.required]),
      longitude : new FormControl([], [Validators.required]),
      latitude : new FormControl([], [Validators.required]),
      unit: new FormControl('', []),
      isTheProjectReraApproved: new FormControl('',[Validators.required]),
     reraNumber: new FormControl(''),

      //additional info
      maxRooms: new FormControl(''),
      beds: new FormControl(''),
      baths: new FormControl(''),
      amenities: this._fb.array([]),
      vicinity: this._fb.array([this.createItem()]),
      numberOfTowers: new FormControl(''),
      videoLink: new FormControl('', [
        Validators.pattern(/^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/)
      ]),
      towers: this._fb.array([]),
      unitName: [[]],
      unitSize: this._fb.array([]),
      unitPrice: this._fb.array([]),
      unitFacing: this._fb.array([]),
      galleryDocuments: new FormControl([], [Validators.required]),

    })
    
  }

  get amenities(): FormArray {
    return this.addpropertyFormGroup.get('amenities') as FormArray;
  }
  
  get items() {
    return this.addpropertyFormGroup.get('vicinity') as FormArray;
  }

  get unitSizeArray(): FormArray {
    return this.addpropertyFormGroup.get('unitSize') as FormArray;
  }

  get unitPriceArray(): FormArray {
    return this.addpropertyFormGroup.get('unitPrice') as FormArray;
  }

  get unitFacingArray(): FormArray {
    return this.addpropertyFormGroup.get('unitFacing') as FormArray;
  }
  get towers(): FormArray {
    return this.addpropertyFormGroup.get('towers') as FormArray;
  }

  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  createItem(): FormGroup {
    return this._fb.group({
      facility: [''],
      distance: [''],
    });
  }
  towerItem(): FormGroup {
    return this._fb.group({
      towerNumber: [''],
      value: ['', Validators.required],
    });
  }

  onTowersChange(): void {
    const numberOfTowers = this.addpropertyFormGroup.get('numberOfTowers')?.value || 0;
    while (this.towers.length !== 0) {
      this.towers.removeAt(0);
    }
    for (let i = 0; i < numberOfTowers; i++) {
      const towerGroup = this.towerItem();
      towerGroup.patchValue({ towerNumber: i + 1 });
      this.towers.push(towerGroup); 
    }
  }
  onUnitTypeChange(event: any, index: number, type: any): void {
    const selectedValues = this.addpropertyFormGroup.controls['unitName'].value || [];

    if (event.target.checked) {
      this.addpropertyFormGroup.controls['unitName'].setValue([...selectedValues, type]);
      this.selectedUnitTypes.push(type);
      this.unitSizeArray.push(
        this._fb.group({
          minSize: ['', Validators.required],
          maxSize: ['', Validators.required],
          unitType: ['', Validators.required]
        }, { validators: this.maxGreaterThanMinValidator('minSize', 'maxSize') }) 
      );

      this.unitFacingArray.push(this._fb.control([],Validators.required));

      this.unitPriceArray.push(
        this._fb.group({
          minPrice: ['', Validators.required],
          maxPrice: ['', Validators.required],
        }, { validators:this. maxGreaterThanMinValidator('minPrice', 'maxPrice') }) 
      );

    } else {
      const removeIndex = this.selectedUnitTypes.indexOf(type);
      if (removeIndex !== -1) {
        this.selectedUnitTypes.splice(removeIndex, 1);
        selectedValues.splice(selectedValues.indexOf(type), 1);
        this.addpropertyFormGroup.controls['unitName'].setValue(selectedValues);
        this.unitSizeArray.removeAt(removeIndex);
        this.unitPriceArray.removeAt(removeIndex);
        this.unitFacingArray.removeAt(removeIndex);
      }
    }
  }

  maxGreaterThanMinValidator(minControlName: string, maxControlName: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const minControl = formGroup.get(minControlName);
      const maxControl = formGroup.get(maxControlName);

      if (minControl && maxControl && minControl.value && maxControl.value) {
        return maxControl.value > minControl.value ? null : { maxLessThanMin: true };
      }
      return null;
    };
  }

  getTypes(index: number): string {
    return this.selectedUnitTypes[index] || '';
  }

  //get assets
  getassetsList() {
    this.propertyService.getassetsList()?.subscribe({
      next: (res: any) => {
        this.assetsList = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  toggleFacing(index: number, direction: string): void {
    const unitFacingArray = this.addpropertyFormGroup.get('unitFacing') as FormArray;
    const selectedFacing: string[] = unitFacingArray.at(index).value || [];
    let facingIndex = selectedFacing.indexOf(direction)

    if (facingIndex > -1) {
      selectedFacing.splice(facingIndex,1)
    } else {
      selectedFacing.push(direction);
    }
    unitFacingArray.at(index).setValue(selectedFacing);

  }


  //get getCategoryList
  getCategoryList() {
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.categoryList = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  getUnitTypes() {
    this.propertyService.getUnitTypes()?.subscribe({
      next: (res: any) => {
        this.unitTypes = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  setConditionalValidators() {
    const selectedCategory = this.selectedCategory; 
    
    if (selectedCategory?.containUnits === false && selectedCategory?.containPriceRange === false ) {
      this.addpropertyFormGroup.get('propertySize')?.setValidators([Validators.required, Validators.pattern(RegExpPatterns.propertySize)]);
      this.addpropertyFormGroup.get('unit')?.setValidators([Validators.required]);
      this.addpropertyFormGroup.get('price')?.setValidators([Validators.required, Validators.pattern(RegExpPatterns.price)]);
    } else {
      this.addpropertyFormGroup.get('propertySize')?.clearValidators();
      this.addpropertyFormGroup.get('unit')?.clearValidators();
      this.addpropertyFormGroup.get('price')?.clearValidators();
    }
    this.addpropertyFormGroup.get('propertySize')?.updateValueAndValidity();
    this.addpropertyFormGroup.get('unit')?.updateValueAndValidity();
    this.addpropertyFormGroup.get('price')?.updateValueAndValidity();
  }


  //get subactegoryList
  getsubactegoryList(categoryId: string) {
    this.propertyService.getsubactegoryList(categoryId)?.subscribe({
      next: (res: any) => {
        this.subCategoryList = res?.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    })
  }

  getAmenitiesList() {
    this.propertyService.getAmenitiesList()?.subscribe({
      next: (res: any) => {
        this.amenitiesList = res?.data;
        const amenitiesArray = this._fb.array(
          this.amenitiesList.map(() => this._fb.control(false)) 
        );
        this.addpropertyFormGroup.setControl('amenities', amenitiesArray);
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

addItem(): void {
  if (this.fieldsLengthValid(this.items.length - 1)) {
      this.items.push(this.createItem());
  }
}
removeItem(index: number): void {
  if (this.items.length > 1) {
      this.items.removeAt(index); 
  }
}
fieldsLengthValid(index: number): boolean {
  const facilityControl = this.items.at(index).get('facility');
  const distanceControl = this.items.at(index).get('distance');
  const isFacilityValid = facilityControl?.value && facilityControl.value.length > 1;
  const isDistanceValid = distanceControl?.value && distanceControl.value.length > 0;
  return isFacilityValid && isDistanceValid;
}

  uploadDoc(event: any, field: string) {
    if (!this.loader) {
      this.loader = {};
    }
    if (!this.fileUploadErrors) {
      this.fileUploadErrors = {};
    }
    this.loader[field] = true;
    this.fileUploadErrors[field] = '';
    const formData = new FormData();
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const allowedPdfType = ['application/pdf'];
    const maxFileSize = 200 * 1024 * 1024;
    for (let i = 0; i < event.target.files.length; i++) {
      const selectedFile = event.target.files[i];
      if (field === 'brochureDocuments' && !allowedPdfType.includes(selectedFile.type)) {
        this.fileUploadErrors[field] = 'Only PDF format is allowed for Brochure.';
        this.loader[field] = false;
        return;
      }
      if (['floorPlanningDocuments', 'buildingPlanDocuments'].includes(field) &&
        ![...allowedImageTypes, ...allowedPdfType].includes(selectedFile.type)) {
        this.fileUploadErrors[field] = 'Only JPG, PNG, JPEG, and PDF formats are allowed for Floor Plan and Building Plan.';
        this.loader[field] = false;
        return;
      }
      if (field === 'galleryDocuments' && !allowedImageTypes.includes(selectedFile.type)) {
        this.fileUploadErrors[field] = 'Only JPG, PNG, and JPEG formats are allowed for Gallery Pictures.';
        this.loader[field] = false;
        return;
      }
      if (selectedFile.size > maxFileSize) {
        this.fileUploadErrors[field] = 'Only files up to 200 MB are allowed.';
        this.loader[field] = false;
        return;
      }
      formData.append('file', selectedFile);
      this.propertyService.uploadFiles(formData)?.subscribe({
        next: (res: any) => {
          this.loader[field] = false;
          if (!this.listOfFiles[field]) {
            this.listOfFiles[field] = [];
          }
          if (!this.uploadfileList[field]) {
            this.uploadfileList[field] = [];
          }
          this.uploadfileList[field].push(res?.data);
          this.listOfFiles[field].push(res.data._id);
          this.addpropertyFormGroup?.controls[field]?.setValue(this.listOfFiles[field]);
          this.addpropertyFormGroup?.controls[field]?.updateValueAndValidity();
        },
        error: (error: any) => {
          this.loader[field] = false;
          console.log(error)
          this.fileUploadErrors[field] = error?.error?.error || 'An error occurred while uploading the file. Please try again.';
          console.error(error);
        }
      });
    }
  }
  
  addVideoLink() {
    const videoControl = this.addpropertyFormGroup.get('videoLink');

    if (videoControl?.invalid) {
      return; 
    }
    const videoLink = this.convertToEmbedUrl(videoControl?.value);
    if (videoLink) {
      if (!this.uploadfileList['videoDocuments']) {
        this.uploadfileList['videoDocuments'] = [];
      }
      this.uploadfileList['videoDocuments'].push(videoLink);
      videoControl?.setValue('');  
    }
  }


  convertToEmbedUrl(url: string): string {
    const videoId = url?.split('v=');
    if (videoId?.[1]) {
      return "https://www.youtube.com/embed/" + videoId[1];
    }
    else if (url.includes('youtu.be')) {
      const shortUrlId = url.split('youtu.be/')[1]?.split('?')[0];
      if (shortUrlId) {
        return "https://www.youtube.com/embed/" + shortUrlId;
      }
    }
    return url;
  }

  removeSelectedFile(index: number, field: string): void {
    if (this.uploadfileList[field] && this.uploadfileList[field].length > index) {
      this.uploadfileList[field]?.splice(index, 1);
      this.listOfFiles[field]?.splice(index, 1);
      this.addpropertyFormGroup.controls[field]?.setValue(this.listOfFiles[field]);
      this.addpropertyFormGroup.controls[field]?.updateValueAndValidity();
    }
  }
  
  formatAddress($event: any) {
    if ($event) {
      this.handlePropertyAddress($event);
    } else {
      this.clearAddress();
    }
  }

  handlePropertyAddress(address: any) {
    this.addpropertyFormGroup.patchValue({ ...address });
    this.formattedAddress = address.formatAddress
    this.markFormGroupTouched(this.addpropertyFormGroup);
    this.addpropertyFormGroup.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  clearAddress() {
    this.addpropertyFormGroup.patchValue({
      line1: '',
      line2: '',
      country: '',
      state: '',
      city: '',
      locality: '',
      pincode: '',
      location: '',
      longitude: '',
      latitude: '',
    });
    this.markFormGroupTouched(this.addpropertyFormGroup);
    this.addpropertyFormGroup.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onClose() {
    this.router.navigate(['/properties-list'])
    this.addpropertyFormGroup.reset();
  }

  generateProperty() {
    this.verifyLoader = true;
    const selectedFeatures = this.amenities.controls
    .map((control : any, index: number) => control.value ? this.amenitiesList[index]._id : null)
    .filter((feature: string | null) => feature !== null);
    const formValues = this.addpropertyFormGroup.value;
    const locationArray = [
      formValues.latitude !== undefined ? formValues.latitude : (formValues.location ? formValues.location[0] : ''),
      formValues.longitude !== undefined ? formValues.longitude : (formValues.location ? formValues.location[1] : '')
  ];
    const propertyData = {
      ...formValues,
      address: {
        line1: this.addpropertyFormGroup.value.line1,
        line2: this.addpropertyFormGroup.value.line2,
        country: this.addpropertyFormGroup.value.country,
        state: this.addpropertyFormGroup.value.state,
        city: this.addpropertyFormGroup.value.city,
        locality: this.addpropertyFormGroup.value.locality,
        pincode: this.addpropertyFormGroup.value.pincode,
        location: locationArray,
        
      },
      advanceFeatures: {
        maxRooms: this.addpropertyFormGroup.value.maxRooms,
        beds: this.addpropertyFormGroup.value.beds,
        baths: this.addpropertyFormGroup.value.baths,
        amenity: selectedFeatures
      },
      size: {
        value: this.addpropertyFormGroup.value.propertySize,
        unitType: this.addpropertyFormGroup.value.unit
      },
      formattedAddress :this.formattedAddress,
      // units: this.addpropertyFormGroup.value.unitName.length > 0 ? this.setUnits() : '',
      ...this.listOfFiles,
      videoDocuments: this.uploadfileList['videoDocuments'],
    };
    if (this.selectedCategory && this.selectedCategory.containUnits) {
      propertyData.units = formValues.unitName.length > 0 ? this.setUnits() : '';
  }
    if (this.addpropertyFormGroup.value.location && this.addpropertyFormGroup.value.location.length === 2) {
      this.positionData = {
        lat: this.addpropertyFormGroup.value.location[0],
        lng: this.addpropertyFormGroup.value.location[1]
      };
    }
    delete propertyData.unitName
    delete propertyData.unitSize
    delete propertyData.unitFacing
    delete propertyData.unitPrice
    delete propertyData.amenities
    delete propertyData.videoLink
    if (this.id !== null && this.id !== undefined) {
      this.propertyService.updateProperty(this.id, propertyData).subscribe({
        next: (res: any) => {
          if (res['success']) {
            this.verifyLoader = false;
            this.router.navigate(['/properties-list'])
          }
        },
        error: (error: any) => {
          this.verifyLoader = true;
          console.error(error);
        }
      });
    } else {
      this.propertyService.generateProperty(propertyData).subscribe({
        next: (res: any) => {
          if (res['success']) {
            this.verifyLoader = false;
            this.router.navigate(['/properties-list'])
          }
        },
        error: (error: any) => {
          this.verifyLoader = true;
          console.error(error);
        }
      });
    }
  }

  setUnits() {
    let unitNames = this.addpropertyFormGroup.value.unitName
    let units: any = []
    unitNames.forEach((val: any, index: number) => {
      units.push({
        unitName: val,
        unitSize: {
          minSize: this.addpropertyFormGroup.value.unitSize[index].minSize,
          maxSize: this.addpropertyFormGroup.value.unitSize[index].maxSize,
          unitType: this.addpropertyFormGroup?.value.unitSize[index].unitType
        },
        unitPrice: {
          minPrice: this.addpropertyFormGroup.value.unitPrice[index].minPrice,
          maxPrice: this.addpropertyFormGroup.value.unitPrice[index].maxPrice
        },
        unitFacing: this.addpropertyFormGroup.value.unitFacing[index]
      })
    })
    return units;
  }
  updateLocation(location: number[]) {
    this.center = {
      lat: location[0],
      lng: location[1]
    };
    this.positionData = {
      lat: location[0],
      lng: location[1]
    };
  }

  clearLocation() {
    this.positionData = {
      lat: 0,
      lng: 0
    };
    this.center = {
      lat: 16.5061743,
      lng: 80.6480153
    };
    this.cdr.detectChanges();
  }

  showPropertyDetails(id: any) {
    this.propertyService?.getsubPropertyList(id).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.verifyLoader = false;
          this.propertyEditData = res.data;
          // Patch form values for simple fields
          this.addpropertyFormGroup.patchValue({
            asset: this.propertyEditData.asset || '',
            categoryList: this.propertyEditData.category || '',
            subcategory: this.propertyEditData.subcategory || '',
            projectName: this.propertyEditData.projectName || '',
            description: this.propertyEditData.description || '',
            projectBy: this.propertyEditData.projectBy || '',
            featured: this.propertyEditData.featured ? true : false,
            line1: this.propertyEditData.address?.line1 || '',
            line2: this.propertyEditData.address?.line2 || '',
            country: this.propertyEditData.address?.country || '',
            state: this.propertyEditData.address?.state || '',
            city: this.propertyEditData.address?.city || '',
            locality: this.propertyEditData.address?.locality || '',
            latitude : this.propertyEditData.address?.location[0] || '',
            longitude : this.propertyEditData.address?.location[1] || '',
            pincode: this.propertyEditData.address?.pincode || '',
            location: this.propertyEditData.address?.location || [],
            unit: this.propertyEditData.size?.unitType || '',
            price : this.propertyEditData.price || '',
            propertySize : this.propertyEditData?.size?.value || '',
            maxRooms: this.propertyEditData.advanceFeatures?.maxRooms || '',
            beds: this.propertyEditData.advanceFeatures?.beds || '',
            baths: this.propertyEditData.advanceFeatures?.baths || '',
            numberOfTowers: this.propertyEditData.numberOfTowers || '',
            isTheProjectReraApproved : this.propertyEditData.isTheProjectReraApproved ? true : false,
            reraNumber : this.propertyEditData.reraNumber || ''
          });

        if (this.propertyEditData.advanceFeatures?.amenity) {
          const amenityTypes = this.propertyEditData.advanceFeatures.amenity.map((item: any) => item._id);

          const amenitiesFormArray = this.addpropertyFormGroup.get('amenities') as FormArray;
          this.amenitiesList.forEach((feature : any, index : number) => {
            const isChecked = amenityTypes.includes(feature._id);
            if (amenitiesFormArray.at(index)) {
              amenitiesFormArray.at(index).patchValue(isChecked);
            }
          });
        }
  
          // Patch 'units' array
          if (this.propertyEditData.units) {
            const unitNames = this.propertyEditData.units.map((unit: any) => unit.unitName);
            this.addpropertyFormGroup.controls['unitName'].setValue(unitNames);
            this.selectedUnitTypes = [...unitNames];
  
            this.unitSizeArray.clear();
            this.unitFacingArray.clear();
            this.unitPriceArray.clear();
  
            this.propertyEditData.units.forEach((unit: any) => {
              this.unitSizeArray.push(this._fb.group({
                minSize: [unit.unitSize?.minSize || ''],
                maxSize: [unit.unitSize?.maxSize || ''],
                unitType: [unit.unitSize?.unitType || '']
              }));
  
              this.unitFacingArray.push(this._fb.control(unit.unitFacing || []));
              
              this.unitPriceArray.push(this._fb.group({
                minPrice: [unit.unitPrice?.minPrice || ''],
                maxPrice: [unit.unitPrice?.maxPrice || '']
              }));
            });
          }
  
          // Patch 'vicinity' array
          if (this.propertyEditData.vicinity) {
            this.items.clear();
            this.propertyEditData.vicinity.forEach((vicinity: any) => {
              this.items.push(this._fb.group({
                distance: [vicinity.distance || ''],
                facility: [vicinity.facility || '']
              }));
            });
          }
  
          // Patch 'towers' array
          if (this.propertyEditData.towers) {
            this.towers.clear();
            this.propertyEditData.towers.forEach((tower: any) => {
              this.towers.push(this._fb.group({
                towerNumber: [tower.towerNumber || ''],
                value: [tower.value || ''],
              }));
            });
          }

          this.uploadfileList = {
            brochureDocuments : this.propertyEditData?.brochure || [],
            floorPlanningDocuments : this.propertyEditData?.floorPlan || [],
            buildingPlanDocuments  : this.propertyEditData?.buildingPlan || [],
            galleryDocuments : this.propertyEditData?.galleryPics || [],
            logoDocuments : this.propertyEditData?.logo || [],
            headerDocuments : this.propertyEditData?.headerSectionPhotos || [],
            videoDocuments : this.propertyEditData?.video || []
          }

          this.listOfFiles = {
            brochureDocuments : this.propertyEditData.brochure.map((item: any)=>item.document),
            floorPlanningDocuments : this.propertyEditData.floorPlan.map((item: any)=>item.document),
            buildingPlanDocuments  : this.propertyEditData.buildingPlan.map((item: any)=>item.document),
            galleryDocuments : this.propertyEditData.galleryPics.map((item: any)=>item.document),
            logoDocuments : this.propertyEditData.logo.map((item: any)=>item.document),
            headerDocuments : this.propertyEditData.headerSectionPhotos.map((item: any)=>item.document)
          }

          this.addpropertyFormGroup.controls['galleryDocuments'].setValue(this.listOfFiles['galleryDocuments']);
          // Update location
          if (this.propertyEditData.address?.location && this.propertyEditData.address?.location.length === 2) {
            this.updateLocation(this.propertyEditData.address.location);
          }
        }
      },
      error: (error: any) => {
        this.verifyLoader = false;
        console.error('Error fetching property details:', error);
      }
    });
  }
  

  onFeaturedPropertyChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const value = selectElement.value === 'true';
    this.addpropertyFormGroup.get('featured')?.setValue(value);
  }

  back() {
    this.router.navigate(['properties-list']);
  }
}
