import { Component, HostListener, ElementRef, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faSearch, faChevronDown, faArrowDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from '../../services/property/property.service';
import { FilterService } from '../../services/filters/filter.service';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnChanges {
  propertyList: any;
  public readonly faSearch = faSearch;
  public readonly faChevronDown = faChevronDown;
  public readonly faFilter = faFilter;
  dropdownOpen = false;
  selectedAmenities: any[] = [];
  locationName: any;
  longitude: any;
  latitude: any;
  amenitiesdata: any;
  line1: any;
  line2: any;
  locality: any;
  city: any;
  state: any;
  amenities: any;
  sortDir: any;
  popularity: any;
  status: any;
  isAdmin: boolean = false;
  route: any;
  isOffCanvasOpen = false;
  isMobileOrTablet = false;
  isMobile = false;
  isTab = false;
  @Input() filterObject: any;
  @Output() searchEvent = new EventEmitter<string>();
  @Output() toggleFavouriteEvent = new EventEmitter<boolean>();
  isFavouriteChecked: boolean = false;
  budgetDropdownOpen = false;
  selectedBudget: any = null;
  minimumPrice: any;
  maximumPrice: any;
  budgetOptions = [
    { value: 500000, label: '5 lakh' },
    { value: 1000000, label: '10 lakh' },
    { value: 2000000, label: '20 lakh' },
    { value: 3000000, label: '30 lakh' },
    { value: 4000000, label: '40 lakh' },
    { value: 5000000, label: '50 lakh' },
    { value: 6000000, label: '60 lakh' },
    { value: 7000000, label: '70 lakh' },
    { value: 8000000, label: '80 lakh' },
    { value: 9000000, label: '90 lakh' },
    { value: 10000000, label: '1 Cr' }
  ];
  maxBudgetOptions = [
    { value: 5000000, label: '50 lakh' },
    { value: 7500000, label: '75 lakh' },
    { value: 10000000, label: '1 Cr' },
    { value: 20000000, label: '2 Cr' },
    { value: 40000000, label: '4 Cr' },
    { value: 60000000, label: '6 Cr' },
    { value: 80000000, label: '8 Cr' },
    { value: 100000000, label: '10 Cr' }
  ];
  minBudgetOptions = [{ value: 0, label: '0 lakh' },...this.budgetOptions];
  selectedMinBudget: number | null = null;
  selectedMaxBudget: number | null = null;
  selectedPropertyType: string | null = null;
  selectedLocation: string = '';
  selectedPopularity: string | null = null;
  public readonly faArrowDown = faArrowDown;

  constructor(
    public router: Router,
    private propertyService: PropertyService,
    private elementRef: ElementRef,
    private filterService: FilterService,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,

  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.route = event.urlAfterRedirects?.split('?')[0];
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route = event.urlAfterRedirects?.split('?')[0];
        this.checkViewportSize();
      }
    });
  }

  ngOnInit(): void {
    this.getCategoryList();
    this.checkViewportSize();
    this.getAmenitiesList();
    this.activatedRoute.url.subscribe(url => {
      this.route = url[0].path;
    });
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterObject'] && changes['filterObject'].currentValue) {
      const currentValuesData = changes['filterObject'].currentValue;
      if (currentValuesData) {
        this.selectedPropertyType = currentValuesData.propertyType || this.selectedPropertyType;
        this.selectedAmenities = currentValuesData.amenities || this.selectedAmenities;
        this.selectedBudget = currentValuesData.budget || this.selectedBudget;
        this.selectedMaxBudget = currentValuesData.maximumPrice || this.selectedMaxBudget;
        this.selectedMinBudget = currentValuesData.minimumPrice || this.selectedMinBudget;
        if (currentValuesData.popularity && currentValuesData.sortDir !== undefined) {
          this.selectedPopularity = this.mapPopularityValue(currentValuesData.popularity, currentValuesData.sortDir);
        }
        if (currentValuesData.line1) {
          this.selectedLocation = currentValuesData.line1;
        } else if (currentValuesData.line2) {
          this.selectedLocation = currentValuesData.line2;
        } else {
          this.selectedLocation = [currentValuesData.locality, currentValuesData.city, currentValuesData.state]
            .filter(part => part)
            .join(', ');
        }
      }
    }
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  navigateToProperties() {
    const filterObject = this.constructFilterObject();
    if (this.selectedPopularity === null) {
      delete filterObject.popularity;
    }
    filterObject.sortDir = this.sortDir;
    const filteredValues = Object.keys(filterObject).reduce((acc: any, key) => {
      if (filterObject[key] !== null && filterObject[key] !== undefined) {
        acc[key] = filterObject[key];
      }
      return acc;
    }, {});
    this.searchEvent.emit(filteredValues)
  }

  getCategoryList() {
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.propertyList = res?.data;
      },
      error: (error: any) => {
        console.error('Error fetching category list:', error);
      }
    });
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  isSelected(amenity: any): boolean {
    return this.selectedAmenities.some(selected => selected._id === amenity._id);
  }

  toggleSelection(amenity: any) {
    const index = this.selectedAmenities.findIndex(selected => selected._id === amenity._id);
    if (index === -1) {
      this.selectedAmenities.push(amenity);
    } else {
      this.selectedAmenities.splice(index, 1);
    }
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const dropdownElement = this.elementRef.nativeElement.querySelector('.custom-dropdown');
    if (dropdownElement && !dropdownElement.contains(target)) {
      this.dropdownOpen = false;
    }
  }

  formatAddress(event: any) {

    this.handlePropertyAddress(event);

  }

  handlePropertyAddress(address: any) {
    this.latitude = address?.location?.length > 0 ? address?.location[0] : '';
    this.longitude = address?.location?.length > 0 ? address?.location[1] : '';
    this.line1 = address?.line1;
    this.line2 = address?.line2;
    this.locality = address?.locality;
    this.city = address?.city;
    this.state = address?.state;
  }

  private constructFilterObject(): any {
    return {
      propertyType: this.selectedPropertyType,
      line1: this.line1,
      line2: this.line2,
      locality: this.locality,
      city: this.city,
      state: this.state,
      longitude: this.longitude,
      latitude: this.latitude,
      budget: this.selectedBudget,
      maximumPrice: this.selectedMaxBudget,
      minimumPrice: this.selectedMinBudget,
      popularity: this.popularity,
      amenities: this.selectedAmenities.length > 0 ? this.selectedAmenities : undefined
    };
  }

  getAmenitiesList() {
    this.propertyService.getAmenitiesList()?.subscribe({
      next: (res: any) => {
        this.amenitiesdata = res?.data;
        this.amenities = this.amenitiesdata.map((item: any) => item._id);
      },
      error: (error: any) => {
        console.error('Error fetching amenities list:', error);
      }
    });
  }

  onPopularityChange(event: any) {
    const value = event.target.value;
    if (value === 'null') {
      this.selectedPopularity = null;
      this.popularity = null;
      this.sortDir = null;
    } else if (value === 'mostViewedAsc') {
      this.popularity = 'mostViewed';
      this.sortDir = 1;
    } else if (value === 'mostViewedDesc') {
      this.popularity = 'mostViewed';
      this.sortDir = -1;
    }
  }

  private mapPopularityValue(popularity: string, sortDir: number): string | null {
    if (popularity === 'mostViewed' && sortDir === 1) {
      return 'mostViewedAsc';
    } else if (popularity === 'mostViewed' && sortDir === -1) {
      return 'mostViewedDesc';
    }
    return null;
  }

  //budget
  toggleBudgetDropdown() {
    this.budgetDropdownOpen = !this.budgetDropdownOpen;
  }

  isBudgetSelected(budget: any): boolean {
    return this.selectedBudget && this.selectedBudget.min === budget.min && this.selectedBudget.max === budget.max;
  }

  onMinBudgetChange(event: any) {
    const selectedMinValue = event.target.value !== 'null' ? parseInt(event.target.value, 10) : null;
    this.selectedMinBudget = selectedMinValue;
    if (selectedMinValue === null) {
      this.minimumPrice = null;
      this.maxBudgetOptions = [...this.maxBudgetOptions];
    } else if (!isNaN(selectedMinValue)) {
      this.minimumPrice = selectedMinValue;
      this.maxBudgetOptions = this.maxBudgetOptions.filter(option => option.value >= selectedMinValue);
      if (this.selectedMaxBudget && this.selectedMaxBudget < selectedMinValue) {
        this.selectedMaxBudget = null;
        this.maximumPrice = null;
      }
    }
  }

  onMaxBudgetChange(event: any) {
    const selectedMaxValue = event.target.value !== 'null' ? parseInt(event.target.value, 10) : null;
    this.selectedMaxBudget = selectedMaxValue;
    if (selectedMaxValue === null) {
      this.maximumPrice = null;
    } else if (!isNaN(selectedMaxValue)) {
      this.maximumPrice = selectedMaxValue;
      if (this.minimumPrice && selectedMaxValue < this.minimumPrice) {
        this.selectedMaxBudget = null;
        this.maximumPrice = null;
      }
    }
  }

  get checkLoginStatus() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  onToggleFavourite(event: any): void {
    this.isFavouriteChecked = event.target.checked;
    this.toggleFavouriteEvent.emit(this.isFavouriteChecked);
  }

  toggleOffCanvas() {
    this.isOffCanvasOpen = !this.isOffCanvasOpen;
  }

  @HostListener('window:resize')
  checkViewportSize() {
    const width = window.innerWidth;
    this.isMobile = width < 768;
    this.isTab = width >= 768 && width < 1200;
    this.isMobileOrTablet = width < 1200;
  }

}
