import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'AutocompleteComponent',
  template: `
  <div [ngClass]= "showStar ? 'add-property-location' : 'filter-property-location'">
    <label [ngClass]= "showStar ? 'addProperty__dropdownLabel' : 'filter__dropdownLabel'" >{{ label }}<span *ngIf="showStar" style="color:red;">*</span></label>
    <input  [ngClass]= "showStar ? 'addProperty-irr' : 'filter-maps-irr'"  type="text" #addresstext  type="text" [formControl]="locationControl" appNoLeadingSpaces>
  </div>
  <div  [ngClass]= "showStar ? 'addProperty-irr' : 'filter-maps-irr-error'">
  <div class="errorText"  *ngIf="locationControl.invalid && (locationControl.dirty || locationControl.touched)"><div><small class="text-danger">Location is required</small></div>
</div>
    
    `,
  // styleUrl: './add-property/add-property.component.scss',
  // styleUrl:'./add-properties/add-properties.component.scss', 
  styleUrls: ['./add-properties/add-properties.component.scss', './filter/filter.component.scss']
})
export class InputAutocompleteComponent implements OnInit, AfterViewInit {
  locationControl = new FormControl('', [Validators.required]);
  @Input() showStar: boolean = true;
  @Input() label: string = 'Enter Location';
  @Input() className: string = '';
  @Input() labelClass: string = '';
  @Input() adressType: string = '';
  @Input() initialLocation: string = '';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;
  queryWait: boolean = false;
  showError: boolean = false;
  savedAddress: any = null;
  formattedAddress : any;

  constructor() {
  }

  ngOnInit() {
    this.locationControl.valueChanges.subscribe((value: any) => {
      if (!value) {
        this.clearAddress();
        this.setAddress.emit(null);
      }
    });

    if (this.initialLocation) {
      this.locationControl.setValue(this.initialLocation);
    }
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    this.showError = true;
    let options = {
      componentRestrictions: { country: 'IN' },
      types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
    };
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
     this.formattedAddress = place.formatted_address
      this.invokeEvent(place);
    });
  }

  getAddrComponent(component: any, componentTemplate: any) {
    for (var i = 0; i < component?.length; i++) {
      let addressType = component[i].types[0];
      if (componentTemplate[addressType]) {
        return component[i][componentTemplate[addressType]];
      }
    }
  }

  getLines(place: any, city: string) {
    let name = place ? place.name : undefined;
    let line1: string = '';
    let line2: string = '';
    if (place && place.formatted_address) {
        if (name && !place.formatted_address.includes(name)) {
            place.formatted_address = name + ", " + place.formatted_address;
        }
        const firstCommaIndex = place.formatted_address.indexOf(',');
        if (firstCommaIndex !== -1) {
            line1 = place.formatted_address.substring(0, firstCommaIndex).trim();
        } else {
            line1 = place.formatted_address.trim(); 
        }
        const cityIndex = place.formatted_address.indexOf(city);
        if (cityIndex !== -1) {
            line2 = place.formatted_address.substring(firstCommaIndex + 1, cityIndex).trim(', ');
            line2 = line2.trim().replace(/,+$/, '');
        } else {
            line2 = place.formatted_address.substring(firstCommaIndex + 1).trim(', ');
        }
    }
    return {
        line1,
        line2,
    };
  }

  invokeEvent(place: any) {
    if (!place || !place.geometry) {
      return;
    }
    let address: any = {};
    address.city = this.getAddrComponent(place.address_components, { locality: 'long_name' });
    address.state = this.getAddrComponent(place.address_components, { administrative_area_level_1: 'long_name' });
    address.country = this.getAddrComponent(place.address_components, { country: 'long_name' });
    address.pincode = this.getAddrComponent(place.address_components, { postal_code: 'long_name' });
    address.locality = this.getAddrComponent(place.address_components, { sublocality_level_1: 'long_name' });
    address.formatAddress = this.formattedAddress
    address.location = [
      place.geometry?.location ? place.geometry.location.lat() : '',
      place.geometry?.location ? place.geometry.location.lng() : '',
    ];
    let lines = this.getLines(place, address.city);
    address.line1 = lines.line1;
    address.line2 = lines.line2;
    this.savedAddress = address;
    this.setAddress.emit(address);
    this.showError = false;
  }

  clearAddress() {
    this.savedAddress = null;
    this.setAddress.emit('');
    this.showError = true;
 }
}
