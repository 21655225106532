<div class="container-fluid">
    <div class="row">
        <div class="col left-section">
            <div class="container">
                <div class="login-box">
                    <div class="left">
                        <img src="../../assets/images/Property box logo with out back ground (1).png" alt="Realtbox Logo" class="logo" (click)="home()">
                        <h2>Sign in</h2>
                        <p>If you don't have an account register</p>
                        <p>You can <strong> Register Here !</strong> </p>

                        <!-- Login Form -->
                        <form [formGroup]="loginForm" *ngIf="showform1" (keydown.enter)="onEnterKey($event, 'loginForm')">
                            <div class="input-container">
                                <fa-icon [icon]="faPhone"></fa-icon>
                                <app-input-filed label="Mobile Number" placeholder="Enter 10 digit mobile number"
                                    [control]="$any(loginForm).controls['mobileNumber']" [starIcon]="'*'"
                                    [allowedType]="'number'" [maxDigits]="10">
                                </app-input-filed>
                            </div>
                            <div class="errorText"
                                *ngIf="loginForm.controls['mobileNumber'].invalid && (loginForm.controls?.['mobileNumber']?.dirty || loginForm.controls['mobileNumber'].touched)">
                                <div *ngIf="loginForm.controls['mobileNumber'].errors?.['pattern']">
                                    <small class="text-danger">Please enter valid mobile number</small>
                                </div>
                                <div *ngIf="loginForm.controls['mobileNumber'].errors?.['required']">
                                    <small class="text-danger">Mobile number is required</small>
                                </div>
                            </div>
                            <div *ngIf="errorMessage">
                                <small class="text-center text-danger">{{ errorMessage }}</small>
                            </div>
                            <app-common-button (clickEvent)="generateOtp()" buttonClass="roundedRegularButton"
                                buttonLabel="Continue" [disabled]="loginForm.invalid" [loadingIcon]="verifyLoader">
                            </app-common-button>
                        </form>

                        <!-- Signup Form -->
                        <form *ngIf="showForm2" [formGroup]="signupForm" (keydown.enter)="onEnterKey($event, 'signupForm')">
                            <div class="input-container">
                                <img class="lock" src="../../assets/images/message 1.svg">
                                <app-input-filed label="Email" placeholder="Enter your email address"
                                    [allowedType]="'email'" [control]="$any(signupForm).controls['email']">
                                </app-input-filed>
                            </div>
                            <div class="errorText"
                                *ngIf="signupForm.controls['email'].invalid && (signupForm.controls?.['email']?.dirty || signupForm.controls['email'].touched)">
                                <div>
                                    <small class="text-danger">Please enter valid email</small>
                                </div>
                            </div>
                            <div class="input-container">
                                <img class="lock" src="../../assets/images/user 1.svg">
                                <app-input-filed label="Name" placeholder="Enter your User name" [allowedType]="'name'"
                                    [control]="$any(signupForm).controls['name']" [starIcon]="'*'">
                                </app-input-filed>
                            </div>
                            <div class="errorText"
                                *ngIf="signupForm.controls['name'].invalid && (signupForm.controls?.['name']?.dirty || signupForm.controls['name'].touched)">
                                <div *ngIf="signupForm.controls['name'].errors?.['pattern']">
                                    <small class="text-danger">Please enter name</small>
                                </div>
                                <div *ngIf="signupForm.controls['name'].errors?.['required']">
                                    <small class="text-danger">Name is required</small>
                                </div>
                            </div>
                            <div *ngIf="errorMessage">
                                <small class="text-center text-danger">{{ errorMessage }}</small>
                            </div>
                            <div class="input-container">
                                <img class="lock" src="../../assets/images/padlock 1.svg">
                                <app-input-filed label="OTP" placeholder="Enter your OTP" [allowedType]="'number'"
                                    [type]="hide?'password':'text'" [control]="$any(signupForm).controls['otp']"
                                    [starIcon]="'*'" [maxDigits]="6">
                                </app-input-filed>
                                <fa-icon class="eye" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                                    [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
                            </div>
                            <div class="errorText"
                                *ngIf="signupForm.controls['otp'].invalid && (signupForm.controls?.['otp']?.dirty || signupForm.controls['otp'].touched)">
                                <div *ngIf="signupForm.controls['otp'].errors?.['pattern']">
                                    <small class="text-danger">Please enter OTP</small>
                                </div>
                                <div *ngIf="signupForm.controls['otp'].errors?.['required']">
                                    <small class="text-danger">OTP is required</small>
                                </div>
                            </div>
                            <div *ngIf="errorMessage">
                                <small class="text-center text-danger">{{ errorMessage }}</small>
                            </div>
                            <div>
                                <app-common-button buttonClass="roundedRegularButton" buttonLabel="Register"
                                    [loadingIcon]="verifyLoader" [disabled]="signupForm.invalid" (click)="submitOtp()">
                                </app-common-button>
                            </div>
                        </form>

                        <!-- OTP Form -->
                        <form *ngIf="showForm3" [formGroup]="createForm" (keydown.enter)="onEnterKey($event, 'createForm')">
                            <div class="input-container">
                                <img class="lock" src="../../assets/images/padlock 1.svg">
                                <app-input-filed label="OTP" placeholder="Enter your OTP" [allowedType]="'number'"
                                    [type]="hide?'password':'text'" [control]="$any(createForm).controls['otp']"
                                    [starIcon]="'*'" [maxDigits]="6">
                                </app-input-filed>
                                <fa-icon class="eye" [icon]="hide ? faEye : faEyeSlash" (click)="hide = !hide"
                                    [attr.aria-label]="'Hide Password'" [attr.aria-pressed]="hide"></fa-icon>
                            </div>
                            <div class="errorText"
                                *ngIf="createForm.controls['otp'].invalid && (createForm.controls?.['otp']?.dirty || createForm.controls['otp'].touched)">
                                <div *ngIf="createForm.controls['otp'].errors?.['pattern']">
                                    <small class="text-danger">Please enter OTP</small>
                                </div>
                                <div *ngIf="createForm.controls['otp'].errors?.['required']">
                                    <small class="text-danger">OTP is required</small>
                                </div>
                            </div>
                            <div *ngIf="errorMessage">
                                <small class="text-center text-danger">{{ errorMessage }}</small>
                            </div>
                            <div>
                                <app-common-button buttonClass="roundedRegularButton" buttonLabel="Submit OTP"
                                    [loadingIcon]="verifyLoader" (click)="submitOtp()" [disabled]="createForm.invalid">
                                </app-common-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-section"></div>
    </div>
    <div class="center-image">
        <!-- <img src="../../assets/images/login.jpeg" alt="Centered Image"> -->
        <video autoplay loop muted playsinline>
            <source src="../../assets/images/WhatsApp Video 2025-01-24 at 10.43.02 AM.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>    
    </div>
</div>