<div class="footer">
    <div class="footer-list">
        <div class="row footer-list-block">
            <div class="col footer-logo">
                <a class="navbar-brand" href="#"><img src="../../assets/images/Property box logo with out back ground (1).png"></a>
            </div>
            <div class="col footer-list-about">

                <ul>
                    <div class="list-item-heading">About</div>
                    <!-- <li>
                        <a class="nav-link" href="javascript:void(0)" (click)="scrollToSection('investors')">Company</a>
                    </li> -->
                    <li> <a class="nav-link" href="javascript:void(0)" (click)="scrollToSection('contactus')">Contact
                            Us</a></li>
                    <!-- <li>Terms and Service</li> -->
                    <li class="privacy-policy" (click)="privacyPolicy()"> Privacy Policy & Terms of Use</li>
                </ul>
            </div>
            <div class="col footer-list-product">
                <ul>
                    <div class="list-item-heading">Product</div>
                    <li> <a class="nav-link" href="javascript:void(0)"
                            (click)="scrollToSection('categories')">Investment Properties</a></li>
                    <li class="pricing_list" (click)="pricingList()">Pricing</li>
                    <!-- <li> <a class="nav-link" href="javascript:void(0)"
                            (click)="scrollToSection('testimonials')">Testimonials</a></li> -->
                </ul>
            </div>
            <!-- <div class="col footer-list-discover">
                <ul>
                    <div class="list-item-heading">Discover</div>
                    <li>Teams</li>
                    <li>Partners</li>
                    <li>Career</li>

                </ul>
            </div> -->
            <div class="col footer-list-followus">
                <ul class="social-media-icons">
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=61566727757731" target="_blank"
                            rel="noopener noreferrer">
                            <img src="../../assets/images/facebook-circle.svg" alt="Facebook">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/property__box?igsh=MWVnYmc2Ym8zZWNudw==" target="_blank"
                            rel="noopener noreferrer">
                            <img src="../../assets/images/instagram.svg" alt="Instagram">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/property-box-private-limited" target="_blank"
                            rel="noopener noreferrer">
                            <img src="../../assets/images/linkedin.svg" alt="LinkedIn">
                        </a>
                    </li>
                    <li>
                        <a href="https://x.com/propertyboxind" target="_blank"
                            rel="noopener noreferrer">
                            <img src="../../assets/images/x.svg" alt="Twitter">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@PropertyBox-pb" target="_blank"
                            rel="noopener noreferrer">
                            <img src="../../assets/images/youtube.svg" alt="Youtube">
                        </a>
                    </li>
                </ul>

                <div class="col app-links">
                    <a href="https://play.google.com/store/apps/details?id=in.axivise.realtbox" target="_blank"
                        rel="noopener noreferrer">
                        <img src="../../assets/images/google-play-badge-logo-svgrepo-com.svg" alt="Play Store">
                    </a>
                    <a href="https://apps.apple.com/gb/app/propertybox/id6503969578" class="app-store" target="_blank"
                        rel="noopener noreferrer">
                        <img src="../../assets/images/download-on-the-app-store-apple-logo-svgrepo-com.svg"
                            alt="App Store">
                    </a>
                </div>

            </div>
        </div>
    </div>
    <div class="copy-rights">
        <div class="copy-rights-content">© 2024 - All  Rights Reserved Property Box India Private Limited </div>
    </div>
</div>