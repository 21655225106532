<div *ngIf="moduleList && moduleList?.length > 0">
    <table class="custom-table-list" aria-label="cred_table">
        <thead>
            <tr class="custom-table-list-headings">
                <th class="custom-col-width-30">Name</th>
                <th class="custom-col-width-10">Image</th>
                <th class="custom-col-width-25">Created on</th>
                <th class="custom-col-width-10">Actions</th>
                <th class="custom-col-width-25">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr class="custom-table-list-row-main" *ngFor="let module of moduleList">
                <td class="custom-col-width-30">{{module.name}}</td>
                <td class=" custom-col-width-10  image">
                    <img [src]="module.categoryDocs[0].objectUrl" alt="{{module.name}}" />
                </td>
                <td class="custom-col-width-25">{{module.created | date : "MMM d, y, h:mm:ss a"}}</td>
                <td class="custom-col-width-10">
                    <span (click)="openOverlay('edit',module)" data-bs-toggle="tooltip" ngbTooltip="Edit"
                        [placement]="'left'">
                        <div class="edit">
                            <img src="../../../assets/images/edit.png">
                        </div>
                    </span>
                </td>
                <td class="custom-col-width-25">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" [id]="'flexSwitchCheck' + module._id" [checked]="module.status === 'Active'"
                        (change)="toggleStatus(module)">
                      </div>
                </td>
            </tr>
        </tbody>
    </table>
    <p class="text-danger m-2 mt-5" *ngIf="errorMessage">{{errorMessage}}</p>
</div>
<div *ngIf="loader">
    <div class="custom-col-width-100 d-flex justify-content-center" style="color: #fbb416;">
        <app-common-icon [loadingIcon]="loader"></app-common-icon>
    </div>
</div>
<div *ngIf="!loader && moduleList?.length === 0">
    <div class="pt-3 custom-col-width-100 text-danger d-flex justify-content-center">
        No Module Found!
    </div>
</div>