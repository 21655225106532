import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from '../../../services/property/property.service';
import { faCloudUploadAlt, faEdit, faL, faPen, faPlus, faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-master-data',
  templateUrl: './add-master-data.component.html',
  styleUrl: './add-master-data.component.scss'
})
export class AddMasterDataComponent implements OnChanges, OnInit {
  assetFormGroup           !: FormGroup;
  selectedDetails: any;
  isEdit: boolean = false;
  loader: boolean = false;
  moduleList: any;
  errorMessage: string = '';
  categoryId: any;
  profileImage: any;
  errorMessage1: any;
  documents: any[] = [];
  addedTypes: any[] = [];



  public readonly userCircleIcon = faUserCircle;
  protected readonly faCloudUploadAlt = faCloudUploadAlt;
  protected readonly faPlus = faPlus;
  public readonly faEdit = faEdit;
  public readonly faPen = faPen;

  @Input() selectedType: any;
  @Input() selectedTypeData: any;
  @Output() closePanel: EventEmitter<any> = new EventEmitter();

  constructor(private _fb: FormBuilder,
    private propertyService: PropertyService
  ) {
    this.createFormGroup();
  }

  ngOnInit(): void {
    if (this.selectedType === 'submodule') {
      this.getModulesList();
    }

    this.assetFormGroup.get('containUnits')?.valueChanges.subscribe(value => {
      if (value) {
        this.showConditionalFields();
      } else {
        this.hideConditionalFields();
      }
    });

    this.assetFormGroup.get('category')?.valueChanges.subscribe((_id) => {
      if (_id) {
        this.categoryId = _id;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTypeData']?.currentValue) {
      this.isEdit = true;
      this.selectedDetails = changes['selectedTypeData']?.currentValue;
      this.patchAsset();
    }
  }

  patchAsset(): void {
    if (this.selectedDetails?.name) {
      this.assetFormGroup.patchValue({
        name: this.selectedDetails.name,
        sequenceNumber : this.selectedDetails.sequenceNumber,
        category: this.selectedDetails.category || '',
        documents: this.selectedDetails.documents,
        containTowers: this.selectedDetails.containTowers,
        containPriceRange: this.selectedDetails.containPriceRange,
        haveFacing: this.selectedDetails.haveFacing,
        containUnits: this.selectedDetails.containUnits,
      });

      if (this.selectedDetails?.unitTypes) {
        this.addedTypes = [...this.selectedDetails.unitTypes];
        this.assetFormGroup.controls['unitTypes'].setValue('');
      }


      if (this.selectedDetails?.categoryDocs && this.selectedDetails.categoryDocs.length > 0) {
        this.profileImage = this.selectedDetails?.categoryDocs[0]?.objectUrl;
      } else {
        this.profileImage = null;
      }
    }
  }

  createFormGroup() {
    this.assetFormGroup = this._fb.group({
      name: new FormControl('', [Validators.required]),
      sequenceNumber: new FormControl([]),
      category: new FormControl('', this.selectedType === 'submodule' ? [Validators.required] : []),
      documents: new FormControl('', this.selectedType === 'module' ? [Validators.required] : []),
      unitTypes: new FormControl([]),
      containUnits: new FormControl(false),
      haveFacing: new FormControl(false),
      containTowers: new FormControl(false),
      containPriceRange: new FormControl(false)
    });
    this.assetFormGroup.get('containUnits')?.valueChanges.subscribe((containUnits: boolean) => {
      if (containUnits) {
        this.assetFormGroup.get('unitTypes')?.setValidators([]);
        this.assetFormGroup.get('haveFacing')?.setValidators([]);
        this.assetFormGroup.get('containPriceRange')?.setValidators([]);
      } else {
        this.assetFormGroup.get('unitTypes')?.clearValidators();
        this.assetFormGroup.get('haveFacing')?.clearValidators();
        this.assetFormGroup.get('containPriceRange')?.clearValidators();
      }

      // Update validity after setting or clearing validators
      this.assetFormGroup.get('unitTypes')?.updateValueAndValidity();
      this.assetFormGroup.get('haveFacing')?.updateValueAndValidity();
      this.assetFormGroup.get('containPriceRange')?.updateValueAndValidity();
    });
  }

  onCloseIconClick(value: any) {
    this.closePanel.emit(value);
    this.assetFormGroup.reset();
  }

  showConditionalFields(): void {
    this.assetFormGroup.get('haveFacing')?.setValidators([]);
    this.assetFormGroup.get('containPriceRange')?.setValidators([]);
    this.assetFormGroup.get('unitTypes')?.setValidators([]);
    this.assetFormGroup.get('haveFacing')?.updateValueAndValidity();
    this.assetFormGroup.get('containPriceRange')?.updateValueAndValidity();
    this.assetFormGroup.get('unitTypes')?.updateValueAndValidity();
  }

  // Hide fields when 'containUnits' is false
  hideConditionalFields(): void {
    this.assetFormGroup.get('haveFacing')?.clearValidators();
    this.assetFormGroup.get('containPriceRange')?.clearValidators();
    this.assetFormGroup.get('unitTypes')?.clearValidators();
    this.assetFormGroup.get('haveFacing')?.updateValueAndValidity();
    this.assetFormGroup.get('containPriceRange')?.updateValueAndValidity();
    this.assetFormGroup.get('unitTypes')?.updateValueAndValidity();
  }

  getModulesList() {
    this.loader = true;
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.loader = false;
        this.moduleList = res?.data;
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  addAssets() {
    this.propertyService.addAssets({
      ...this.assetFormGroup.value
    }).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.onCloseIconClick(true);
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  editAssests() {
    this.propertyService.editAssets(this.assetFormGroup.value, this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.loader = false;
          this.onCloseIconClick(true);
        }
      }, error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  addModule() {
    let obj: any = {
      name: this.assetFormGroup.value.name,
      sequenceNumber: this.assetFormGroup.value.sequenceNumber,
      documents: this.documents,
      unitTypes: this.addedTypes,
      containUnits: this.assetFormGroup.value.containUnits,
      haveFacing: this.assetFormGroup.value.haveFacing,
      containTowers: this.assetFormGroup.value.containTowers,
      containPriceRange: this.assetFormGroup.value.containPriceRange
    }
    this.propertyService.addModule(obj).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.onCloseIconClick(true);
        this.propertyService.setModule(this.selectedType);
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  editModule() {
    let payload: any = {
      name: this.assetFormGroup.value.name,
      sequenceNumber: this.assetFormGroup.value.sequenceNumber,
      documents: this.documents,
      unitTypes: this.addedTypes,
      containUnits: this.assetFormGroup.value.containUnits,
      haveFacing: this.assetFormGroup.value.haveFacing,
      containTowers: this.assetFormGroup.value.containTowers,
      containPriceRange: this.assetFormGroup.value.containPriceRange
    }
    this.propertyService.editModule(payload, this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.loader = false;
          this.onCloseIconClick(true);
          this.propertyService.setModule(this.selectedType);
        }
      }, error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  addSubModule() {
    this.propertyService.addSubModule({
      ...this.assetFormGroup.value,
    }, this.categoryId).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.onCloseIconClick(true);
        this.propertyService.setSubModule(this.selectedType);
      },
      error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  editSubModule() {
    this.propertyService.editSubModule(this.assetFormGroup.value, this.selectedTypeData?._id)?.subscribe({
      next: (res) => {
        if (res) {
          this.loader = false;
          this.onCloseIconClick(true);
          this.propertyService.setSubModule(this.selectedType);
        }
      }, error: (error: any) => {
        this.errorMessage = error.error?.error?.message;
        this.loader = false;
      }
    });
  }

  onSubmit() {
    if (this.assetFormGroup.valid) {
      this.loader = true;
      if (this.selectedType === 'assets') {
        if (this.isEdit) {
          this.editAssests();
        } else {
          this.addAssets();
        }
      }
      if (this.selectedType === 'module') {
        if (this.isEdit) {
          this.editModule();
        } else {
          this.addModule();
        }
      }
      if (this.selectedType === 'submodule') {
        if (this.isEdit) {
          this.editSubModule();
        } else {
          this.addSubModule();
        }
      }
    }
  }

  upload(event: any) {
    if (event?.target?.files.length) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      if (file.size / 1024 <= 5000) {
        this.propertyService.uploadFiles(formData).subscribe({
          next: (res: any) => {
            this.profileImage = res.data.objectUrl;
            this.documents.push(res.data._id);
          },
          error: (error: any) => {
            this.errorMessage = 'Only jpeg,png and jpg formats are allowed';
            this.errorMessage1 = '';
          }
        });
      } else {
        this.errorMessage1 = 'Image size should be less than 25MB';
      }
    }
  }

  addData() {
    if (this.assetFormGroup?.controls['unitTypes'].value) {
      this.addedTypes.push(this.assetFormGroup?.controls['unitTypes'].value);
      this.assetFormGroup?.controls['unitTypes'].reset();
    }
  }

  deleteFieldValues(field: any) {
    this.addedTypes = this.addedTypes.filter(item => item !== field);
  }

}
